@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
:root {
    --light-gray-font: #909090;
    --light-gray-font2: #939393;
    --dark-gray-font: #696969;
    --dark-gray: #252525;
    --light-brown: #98762d;
    --dark-brown: #7d6125;
    --dark-brown-trans: #7d6125BB;
    --dark-brown-font: #7e6227;
    --light-yellow: #fbf3e4;
    --height-news: 12vh;
    --height-news_m: 10vh;

    --menu-icon-top: 1vh;
    /*--menu-icon-gray: #B0B0B0;*/
    --menu-icon-gray: var(--light-yellow);
    --menu-icon-height: 0.4vh;
    --menu-icon-width: 4vh;
    --menu-icon-width_small: 2.7vh;
    /*--menu-icon-spacing: 0.7rem;*/
    --menu-icon-spacing: 1.5vh;
}
@font-face {
    font-family: 'Hanie'; /* Cursiva */
    src: url(/static/fonts/Hanie.e340970.ttf); /*URL to font*/
}
@font-face {
  font-family: 'NomineeExtraLight'; /*a name to be used later*/
  src: url(/static/fonts/NomineeExtraLight.cac2723.otf); /*URL to font*/
}
@font-face {
  font-family: 'NomineeMedium'; /*a name to be used later*/
  src: url(/static/fonts/NomineeMedium.b563c73.otf); /*URL to font*/
}
@font-face {
  font-family: 'NomineeMediumExtended'; /*a name to be used later*/
  src: url(/static/fonts/NomineeMediumExtended.10a720a.otf); /*URL to font*/
}
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    padding: 0;
    margin: 0;
    /*background-color: yellow;*/
}

/*
    DEMO STYLE
*/
p[data-v-33bced4a] {
    /*font-family: 'Poppins', sans-serif;*/
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}
a[data-v-33bced4a], a[data-v-33bced4a]:hover, a[data-v-33bced4a]:focus {
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.navbar[data-v-33bced4a] {
    padding: 2.3vw 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}
.navbar-btn[data-v-33bced4a] {
    -webkit-box-shadow: none;
            box-shadow: none;
    outline: none !important;
    border: none;
}
.line[data-v-33bced4a] {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar[data-v-33bced4a] {
    width: 78%;
    position: fixed;
    top: 0;
    left: -78%;
    height: 100vh;
    z-index: 101;
    /*background: #0171bbBB;*/
    /*background-color: red;*/
    background-color: var(--dark-brown-trans);
    /*color: #fff;*/
    color: var(--light-yellow);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow-y: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;

    -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
#sidebar[data-v-33bced4a]::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
    display: none;
}
#sidebar.active[data-v-33bced4a] {
    left: 0;
}
#dismiss[data-v-33bced4a] {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #549CCB;
    position: absolute;
    top: 20px;
    left: 2.3vw;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.overlay[data-v-33bced4a] {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(10, 10, 10, 0.6);
    z-index: 98;
    display: none;
}
#sidebar .sidebar-header[data-v-33bced4a] {
    padding: 20px;
    background-color: var(--dark-brown);
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
#sidebar ul.components[data-v-33bced4a] {
    padding: 20px 0;
    /*border-bottom: 1px solid #47748b;*/
}
#sidebar ul p[data-v-33bced4a] {
    color: #fff;
    padding: 10px;
}
#sidebar ul li a[data-v-33bced4a] {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a[data-v-33bced4a]:hover {
    /*color: #0171bb;*/
    color: var(--dark-brown);
    background: #fff;
}
#sidebar ul li.active > a[data-v-33bced4a], a[aria-expanded="true"][data-v-33bced4a] {
}
a[data-toggle="collapse"][data-v-33bced4a] {
    position: relative;
}
a[aria-expanded="false"][data-v-33bced4a]::before, a[aria-expanded="true"][data-v-33bced4a]::before {
    content: '\E259';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 1.0em;
}
a[aria-expanded="true"][data-v-33bced4a]::before {
    content: '\E260';
}
ul ul a[data-v-33bced4a] {
    font-size: 1.05em !important;
    padding-left: 2.3vw !important;
}
ul.CTAs[data-v-33bced4a] {
    padding: 20px;
}
ul.CTAs a[data-v-33bced4a] {
    text-align: center;
    font-size: 1.0em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content[data-v-33bced4a] {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}
@media (min-width:320px)  {
    /* smartphones, iPhone, portrait 480x320 phones */
#sidebar[data-v-33bced4a] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
#sidebar[data-v-33bced4a] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:521px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
#sidebar[data-v-33bced4a] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:641px)  {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
#sidebar[data-v-33bced4a] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:861px)  {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
#sidebar[data-v-33bced4a] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:1125px) {
    /* big landscape tablets, laptops, and desktops */
#sidebar[data-v-33bced4a] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:1281px) {
    /* hi-res laptops and desktops */
#sidebar[data-v-33bced4a] {
        width: 30%;
        left: -30%;
}
}
/*https://codepen.io/jorenerene/pen/vxKbJb*/
/*https://codepen.io/bennettfeely/pen/twbyA*/
.navicon-button[data-v-33bced4a] {
    display: inline-block;
    padding: 1.0625rem 0.5rem;
    -webkit-transition: 0.15s;
    transition: 0.15s;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 0.8;
    /*background-color: red;*/
}
.navicon-button .navicon[data-v-33bced4a]:before, .navicon-button .navicon[data-v-33bced4a]:after {
    -webkit-transition: 0.15s;
    transition: 0.15s;
}
/*.navicon-button:not(:focus):hover > .navicon {*/
/*transition: 0.3s;*/
/*width: 2.5rem;*/
/*!*background-color: red;*!*/
/*}*/
/*.navicon-button:hover {*/
/*transition: 0.3s;*/
/*opacity: 1;*/
/*}*/
/*.navicon-button:hover .navicon:before, .navicon-button:hover .navicon:after {*/
/*transition: 0.15s;*/
/*}*/
/*.navicon-button:hover .navicon:before {*/
/*top: 0.925rem;*/
/*}*/
/*.navicon-button:hover .navicon:after {*/
/*top: -0.925rem;*/
/*}*/
.navicon[data-v-33bced4a] {
    position: relative;
    width: var(--menu-icon-width_small);
    /*height: 0.3125rem;*/
    height: var(--menu-icon-height);
    /*max-height: var(--height-menu-icon);*/
    background: var(--menu-icon-gray);
    /*background-color: green;*/
    -webkit-transition: 0.5s;
    transition: 0.5s;
    /*border-radius: 2.5rem;*/
    border-radius: 1rem;
}
.navicon[data-v-33bced4a]:before, .navicon[data-v-33bced4a]:after {
    display: block;
    content: "";
    /*height: 0.3125rem;*/
    height: var(--menu-icon-height);
    /*max-height: var(--height-menu-icon);*/
    /*min-height: var(--height-menu-icon);*/
    /*height: 0.2rem;*/
    width: var(--menu-icon-width);
    background: var(--menu-icon-gray);
    position: absolute;
    z-index: 998;
    -webkit-transition: 0.3s 0.15s;
    transition: 0.3s 0.15s;
    border-radius: 2rem;
    /*background-color: blue;*/
}
.navicon[data-v-33bced4a]:before {
    /*top: 0.825rem;*/
    top: var(--menu-icon-spacing);
}
.navicon[data-v-33bced4a]:after {
    /*top: -0.825rem;*/
    top: calc(-1 * var(--menu-icon-spacing));
}
.open:not(.steps) .navicon[data-v-33bced4a]:before, .open:not(.steps) .navicon[data-v-33bced4a]:after {
    top: 0 !important;
}
.open .navicon[data-v-33bced4a]:before, .open .navicon[data-v-33bced4a], .open .navicon[data-v-33bced4a]:after {
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
/* × */
.open.x .navicon[data-v-33bced4a] {
    background: transparent;
}
.open.x .navicon[data-v-33bced4a]:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.open.x .navicon[data-v-33bced4a]:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.open.x .navicon[data-v-33bced4a] {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.section[data-v-33bced4a] {
    position: fixed;
    display: inline-block;
    top: 0;
    left: 0;
    /*margin: -42px auto;*/
    margin-top: var(--menu-icon-top);
    margin-left: 1vw;
    /*background: red;*/
    /*overflow: hidden;*/
    z-index: 100000;
    /* Smoother animations */
}
.section *[data-v-33bced4a], .section *[data-v-33bced4a]:before, .section *[data-v-33bced4a]:after {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
@media (min-width:320px)  {
}
@media (min-width:641px)  {
    /*Botei esso aqui, pois quando tava clicando no botao do menu no celular, quando voltava ele fica expandido (a linha do meio.)*/
.navicon-button:hover > .navicon[data-v-33bced4a] {
        -webkit-transition: 0.3s;
        transition: 0.3s;
        width: var(--menu-icon-width);

        /*background-color: pink;*/
}
}

.top-bar[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    margin-top: 5vh;
    height: 5vh;
    width: 100%;
    background-color: #000000AA;
    z-index: 10;
}
.top-bar-item[data-v-33bced4a] {
    color: white;
    font-family: 'NomineeExtraLight';
    cursor: pointer;
}
.top-logo[data-v-33bced4a], .top-logo_b[data-v-33bced4a] {
    height: 8vh;
    /*height: 4vh;*/
    /*height: 160%;*/
    /*height: 90%;*/
    margin-top: 1.5vh;
    /*margin-top: 0vh;*/
    /*margin-top: 0.85%;*/
    cursor: pointer;
}
.top-logo_b[data-v-33bced4a] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(81%, var(--light-yellow)), color-stop(19%, transparent));
    background: linear-gradient(180deg, var(--light-yellow) 81%, transparent 19%);
}
.top-bar-item[data-v-33bced4a]:hover {
    border-bottom: 1px solid white;
}
.tx_title[data-v-33bced4a], .tx_title_gastro[data-v-33bced4a], .tx_title_historia[data-v-33bced4a], .tx_title_localizacao[data-v-33bced4a], .tx_title_comodidades[data-v-33bced4a], .tx_title_acomoda[data-v-33bced4a], .tx_title_reservas[data-v-33bced4a], .tx_title_newsletter[data-v-33bced4a] {
    color: black;
    font-size: 11vh;
    font-family: 'Hanie';
}
.tx_title_historia[data-v-33bced4a] {
    font-size: 9vh;
    text-align: left;
}
.tx_title_localizacao[data-v-33bced4a] {
    color: var(--light-yellow);
    margin-bottom: 2vh;
    font-size: 7vh;
}
.tx_title_comodidades[data-v-33bced4a] {
    color: var(--light-brown);
    margin-top: -2vh;
    margin-bottom: 0vh;
    height: 20vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    /*background-color: blueviolet;*/
}
.tx_title_acomoda[data-v-33bced4a] {
    color: var(--dark-brown);
    margin-bottom: -5vh;
    font-size: 8vh;
    /*width: 100%;*/
    /*background-color: yellow;*/
}
.tx_title_reservas[data-v-33bced4a] {
    color: var(--dark-brown-font);
    margin-bottom: -2vh;
    font-size: 9vh;
}
.tx_descricao[data-v-33bced4a], .tx_descricao_gastro[data-v-33bced4a], .tx_subtitle_acomoda[data-v-33bced4a], .tx_subtitle_comodidade[data-v-33bced4a] {
    color: var(--light-gray-font);
    font-size: 2.2vh;
    line-height: 4.5vh;
    font-family: 'NomineeMediumExtended';
    text-align: left;
}
.tx_title_galeria[data-v-33bced4a] {
    font-size: 5vh;
    margin-top: 15vh;
    margin-bottom: -10vh;
    color: var(--light-brown);
}
.tx_descricao_gastro[data-v-33bced4a], .tx_subtitle_acomoda[data-v-33bced4a], .tx_subtitle_comodidade[data-v-33bced4a] {
    font-size: 1.6vh;
    line-height: 3.5vh;
}
.tx_comodidades_title[data-v-33bced4a] {
    font-family: 'NomineeExtraLight';
    color: white;
    font-size: 3vh;
}
.tx_subtitle_comodidade[data-v-33bced4a] {
    text-align: right;
}
.tx_comodidades_desc[data-v-33bced4a] {
    font-family: 'NomineeMediumExtended';
    color: white;
    font-size: 2vh;
    font-size: 1.8vh;
    line-height: 3vh;
}
.tx_endereco[data-v-33bced4a] {
    font-family: 'NomineeMediumExtended';
    color: var(--dark-gray-font);
    font-size: 2.5vh;
    line-height: 4vh;
}
.tx_gastronomia_title[data-v-33bced4a], .tx_gastronomia_title_s[data-v-33bced4a] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    font-size: 2.5vh;
    line-height: 7vh;
    cursor: pointer;
    white-space: pre;
}
.tx_gastronomia_title_s[data-v-33bced4a] {
    border-bottom: 1px solid var(--light-brown);
}

/*.tx_gastronomia_title:hover {*/

/*border-bottom: 1px solid var(--light-brown);*/

/*}*/
.tx_social_gastronomia[data-v-33bced4a] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    font-size: 2.5vh;
    line-height: 4vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}
.tx_social_gastronomia[data-v-33bced4a]:hover {
    border-bottom: 2px solid var(--light-brown);
}
.tx_descricao_acomoda[data-v-33bced4a], .tx_descricao_acomoda_topics[data-v-33bced4a] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    width: 70%;
    /*white-space: pre;*/
    /*background-color: red;*/
    text-align: left;
    font-size: 1.7vh;
    line-height: 4vh;
    /*display: flex;*/
}
.tx_descricao_acomoda[data-v-33bced4a] {
    white-space: unset;
    /*margin-bottom: 2vh;*/
    /*margin-top: 5vh;*/
    font-size: 2vh;
    line-height: 5vh;
    /*background-color: red;*/
    /*min-height: 65vh;*/
    text-align: left;
    height: 70vh !important;
    min-height: 70vh !important;
    /*text-align: justify;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*align-content: center;*/
}
.tx_acomodacaoes_subtitle[data-v-33bced4a], .tx_acomodacaoes_subtitle_s[data-v-33bced4a] {
    font-family: 'NomineeMediumExtended';
    color: black;
    cursor: pointer;
    margin-bottom: -4vh;
}
.tx_acomodacaoes_subtitle[data-v-33bced4a]:hover {
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    padding-top: 0.2vw;
    padding-bottom: 0.05vw;
}
.tx_acomodacaoes_subtitle_s[data-v-33bced4a] {
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    padding-top: 0.2vw;
    padding-bottom: 0.05vw;
}
.im_acomodacoes[data-v-33bced4a] {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.dv_descricao_acomoda[data-v-33bced4a] {
    width: 30%;
    height: 70vh;
    background-color: var(--dark-brown);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: scroll;
}

/*background do scroll bar*/
.dv_descricao_acomoda[data-v-33bced4a]::-webkit-scrollbar-track
{
    /*-webkit-box-shadow: inset 0 0 6px rgba(1,0,0,0.3);*/
    -webkit-box-shadow: none;
    border-radius: 10px;
    /*background-color: #FF0000;*/
    background-color: transparent;
}

/*background do scroll bar*/
.dv_descricao_acomoda[data-v-33bced4a]::-webkit-scrollbar
{
    width: 1vw;
    /*background-color: #FF00F5;*/
    background-color: transparent;
}

/* FORE GROUND do scroll bar*/
.dv_descricao_acomoda[data-v-33bced4a]::-webkit-scrollbar-thumb
{
    border-radius: 8px;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,1);*/
    -webkit-box-shadow: none;
    background-color: var(--light-yellow);
    /*background-color: red;*/
}
.root[data-v-33bced4a] {
    width: 100%;
    height: 100vh;
    display: block;
    /*background-color: red;*/
}
.im_slider[data-v-33bced4a] {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.im_historia[data-v-33bced4a] {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_ic_comodidades[data-v-33bced4a] {
    margin-right: 0.5vw;
    width: 2vw;
    height: 2vw;
}
.im_gastronomia[data-v-33bced4a] {
    width: 100%;
    height: 63vh;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 1;
}
.dv_im_container[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: yellow;*/
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.im_galery[data-v-33bced4a] {
    width: 23vw;
    height: 18vw;
    -o-object-fit: cover;
       object-fit: cover;
    /*background-color: red;*/
    padding: 1vw;
}
.im_social[data-v-33bced4a] {
    /*width: 2.5vh;*/
    height: 2.8vh;
    margin: 0.3vh;
    -o-object-fit: contain;
       object-fit: contain;
}
.dv_gastronomia_overlay[data-v-33bced4a] {
    width: 100%;
    height: 63vh;
    /*display: flex;*/
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #000000CC;
    z-index: 10;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_gastronomia[data-v-33bced4a] {
    width: 100%;
    height: 63vh;
    background-image: url('/static/imgs/im_rest01.jpg');
    background-size: cover;
    background-position: center;
    /*cursor: pointer;*/
}

/*.dv_gastronomia:hover > .dv_gastronomia_overlay {*/

/*display: flex;*/

/*}*/
.dv_colum_gastronomia[data-v-33bced4a], .dv_colum_gastronomia3[data-v-33bced4a] {
    width: 19.5%;
    height: 100%;
    /*background-color: red;*/
    /*margin-bottom: 1vh;*/
}
.dv_colum_gastronomia:hover .tx_gastronomia_title[data-v-33bced4a] {
    border-bottom: 1px solid var(--light-brown);
}
.dv_colum_gastronomia3:hover .tx_gastronomia_title[data-v-33bced4a] {
    border-bottom: 1px solid var(--light-brown);
}
.dv_colum_gastronomia:hover .dv_gastronomia_overlay[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.dv_colum_gastronomia3:hover .dv_gastronomia_overlay[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.dv_colum_gastronomia_container[data-v-33bced4a] {
    width: 100%;
    height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.dv_row_gastronomia_container[data-v-33bced4a] {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
         -ms-flex-direction: row;
             flex-direction: row;
     -webkit-box-align: center;
         -ms-flex-align: center;
             align-items: center;
     height: 8vh;
}
.dv_gastro_divisor[data-v-33bced4a] {
    width: 1px;
    height: 100%;
    background-color: var(--light-brown);
    margin-right: 1vw;
    margin-left: 1vw;
}
.home[data-v-33bced4a], .historia[data-v-33bced4a], .comodidades[data-v-33bced4a], .localizacao[data-v-33bced4a], .gastronomia[data-v-33bced4a], .acomodacoes[data-v-33bced4a], .reservas[data-v-33bced4a], .footbar[data-v-33bced4a], .faleconosco[data-v-33bced4a], .newsletter[data-v-33bced4a] {
    height: 100vh;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.home[data-v-33bced4a] {
    /*display: unset;*/
    height: 100%;
    /*background-color: blue;*/
}
.dv_home_selos[data-v-33bced4a] {
    position: absolute;
    bottom: 6vh;
    right: 4vw;
    z-index: 0;
    /*background-color: red;*/
}
.historia[data-v-33bced4a] {
    background-color: var(--light-yellow);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.dv_historia[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: 99vh;
}
.dv_historia_sub1[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 60%;
    max-height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 5vw;
    overflow: no-display;
}
.dv_historia_sub2[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-right: 10vw;
}
.comodidades[data-v-33bced4a] {
    background-color: var(--dark-gray);
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.localizacao[data-v-33bced4a] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    background-position: initial;
    /*background-repeat: no-repeat;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-size: cover;
}
.dv_localizacao_container[data-v-33bced4a] {
    width: 80%;
    height: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.dv_localizacao_sub1[data-v-33bced4a] {
    height: 100%;
    width: 40%;
    background-color: var(--light-yellow);
}
.dv_localizacao_sub2[data-v-33bced4a] {
    height: 100%;
    width: 60%;
}
.gastronomia[data-v-33bced4a] {
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.acomodacoes[data-v-33bced4a] {
    background-color: var(--light-yellow);
}
.dv_acomodacoes_tit_cont[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 20vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.dv_acomodacoes_container[data-v-33bced4a] {
    width: 100%;
    height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    lex-direction: row;
}
.dv_row_acomodacoes_container_ft[data-v-33bced4a], .dv_row_comodidades_container_ft[data-v-33bced4a], .dv_row_gastro_container_ft[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
}
.dv_row_acomodacoes_container_ft[data-v-33bced4a] {
    /*background-color: yellow;*/
    width: 30%;
}
.dv_row_acomodacoes_container[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    /*background-color: red;*/
    /*padding-right: 10%;*/
    width: 100%;
}
.dv_acomodacoes_sub1[data-v-33bced4a] {
    width: 70%;
    height: 100%;
}
.reservas[data-v-33bced4a] {
    background-color: var(--dark-gray);
}
.faleconosco[data-v-33bced4a], .newsletter[data-v-33bced4a] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    /*background-color: pink;*/
    background-position: center;
    background-size: cover;
    padding-top: 10vh;
    padding-right: 5%;
    padding-left: 5%;
    max-width: 90vw;
    /*height: 40vh;*/
}
.newsletter[data-v-33bced4a] {
    height: 90vh;
    padding-top: 20vh
}
.footbar[data-v-33bced4a] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    background-position: center;
    background-size: cover;
    height: 20vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*background-color: red;*/
}
.dv_footbar_container[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: 100%;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.dv_footbar_colum0[data-v-33bced4a], .dv_footbar_colum[data-v-33bced4a], .dv_footbar_colum2[data-v-33bced4a] {
    width: 20%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.tx_footbar[data-v-33bced4a], .tx_footbar_desc[data-v-33bced4a] {
    color: white;
    font-family: 'NomineeExtraLight';
    white-space: pre;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2vh;
    margin-bottom: 2vh;
    /*background-color: blue;*/
}
.tx_footbar_desc[data-v-33bced4a] {
    font-family: 'NomineeMedium';
    margin-top: 0;
    margin-bottom: 0;
}
.dv_colum_comodidades[data-v-33bced4a] {
    /*background-color: red;*/
    width: 25%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*align-items: flex-start;*/
    /*align-content: flex-start;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.dv_colum_comodidades_container[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    height: 65vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 4vw;
    padding-left: 4vw;
    /*background-color: red;*/
}
.dv_row_comodidades_container[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 8vh;
    margin-top: 8vh;
    /*background-color: red;*/
}
.dv_line_comodidades[data-v-33bced4a] {
    /*background-color: blue;*/
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    text-align: left;
    margin-bottom: 5vh;
}
.bt_enviar[data-v-33bced4a] {
    margin-top: .5vh;
    color: white;
    border-width: 2px;
    border-style: solid;
    font-size: 1.7vh;
    display: inline-block;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    cursor: pointer;
}
.popup_overlay[data-v-33bced4a], .popup_overlay2[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100%;
    background-color: #000000AA;
    z-index: 10;
}
.popup_overlay2[data-v-33bced4a] {
    position: relative;
    margin-top: -100vh;
}
.dv_popup_mail[data-v-33bced4a], .dv_popup_mail2[data-v-33bced4a], .dv_popup_mail3[data-v-33bced4a] {
    background-color: var(--dark-brown-font);
    width: 30%;
    height: 30vh;
    padding: 2vh;
}
.dv_popup_mail[data-v-33bced4a] {
    display: block;
    width: 620px;
    height: 370px;
}
.dv_popup_mail2[data-v-33bced4a] {
    background-color: var(--light-gray-font);
}
.dv_popup_image[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 50vh;
    cursor: pointer;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_popup_checkin[data-v-33bced4a] {
    width: 30%;
    height: 30vh;
    background-image: url("/static/imgs/pop_checkin2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.dv_popup_mail3[data-v-33bced4a] {
    width: 25%;
    height: 25vh;
    background-color: var(--light-gray-font2);
}
.im_whats_over[data-v-33bced4a] {
    width: 80px;
    height: 80px;
    width: 8vh;
    height: 8vh;
    position: fixed;
    z-index: 10;
    bottom: 14vh;
    right: 2.5vh;
    cursor: pointer;
}
.tx_popup1[data-v-33bced4a], .tx_popup2[data-v-33bced4a], .tx_popup2_2[data-v-33bced4a] {
    font-family: 'NomineeExtraLight';
    font-size: 4vh;
    color: var(--light-yellow);
}
.tx_popup2[data-v-33bced4a], .tx_popup2_2[data-v-33bced4a] {
    font-family: 'NomineeMedium';
    padding-top: 0.5vh;
    padding-left: 0.3vh;
    background-color: var(--light-yellow);
    color: var(--dark-brown-font);
}
.tx_popup2_2[data-v-33bced4a] {
    color: var(--light-gray-font);
}
.tx_popup3[data-v-33bced4a], .tx_popup4[data-v-33bced4a] {
    font-family: 'NomineeMedium';
    font-size: 2vh;
    color: var(--light-yellow);
}
.tx_popup4[data-v-33bced4a] {
    font-size: 0.9vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: 'NomineeMediumExtended';
}
.ipt_popup[data-v-33bced4a] {
    width: 100%;
    height: 3vh;
    font-size: 2vh;
    text-align: center;
    color: var(--dark-brown-font);
}
.dv_colum_reservas[data-v-33bced4a] {
    width: 25%;
    height: 100%;
}
.dv_reservas_container[data-v-33bced4a] {
    width: 100%;
    height: 60vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    padding-top: 10vh;
}
.dv_reservas_tit_cont[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 9vh;
    margin-top: 10vh;
}
.dv_reservas_divisor[data-v-33bced4a] {
    width: 1px;
    height: 100%;
    background-color: var(--dark-brown-font);
    margin-right: 1vw;
    margin-left: 1vw;
}
.dv_reservas_texto[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.tx_reservas1[data-v-33bced4a], .tx_reservas2[data-v-33bced4a], .tx_reservas3[data-v-33bced4a] {
    font-family: 'NomineeMedium';
    color: var(--light-brown);
    font-size: 3vh;
}
.tx_reservas2[data-v-33bced4a] {
    background-color: var(--light-brown);
    color: var(--light-yellow);
    padding: 1vh;
    cursor: pointer;
}
.tx_reservas3[data-v-33bced4a] {
    /*margin-top: 1vh;*/
    font-size: 3.5vh;
    line-height: 8vh;
    margin-right: 2vw;
    margin-left: 2vw;
}
.dv_box_calendar[data-v-33bced4a] {
    border-style: solid;
    border-width: 2px;
    border-color: var(--light-brown);
    height: 40vh;
    padding-top: 1vh;
    /*background-color: red;*/
}
.sel_reservas[data-v-33bced4a], .sel_reservas2[data-v-33bced4a] {
    border:0px;
    outline:0px;
    border: none;
    scroll-behavior: smooth;
    background-color: white;
    -webkit-appearance:none;
    font-family: 'NomineeMedium';
    font-size: 2vh;
    color: white;
    background-color: var(--dark-gray);
    border-color: var(--light-brown);
    border-style: solid;
    border-width: 2px;
    border-radius: 0;
    width: 3vw;
    padding-top: 1vh;
    padding-left: 1vh;
    padding-bottom: 0.5vh;
}
.sel_reservas2[data-v-33bced4a] {
    width: 10vw;
}
.tb_reserva[data-v-33bced4a], .tb_reserva_s[data-v-33bced4a], .tb_reserva_i[data-v-33bced4a] {
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    color: var(--light-yellow);
}

/* Semanas */
.tb_reserva_sem[data-v-33bced4a] {
    color: var(--light-brown);
    font-size: 1.8vh;
}

/* Selecionado */
.tb_reserva_s[data-v-33bced4a] {
    border-color: var(--light-brown);
}

/* Intervalo */
.tb_reserva_i[data-v-33bced4a] {
    background-color: black;
    /*border-color: var(--light-brown);*/
}
body[data-v-33bced4a] {
    padding: 10px;
}

/****************** SLIDER BANNER ******************/
.img_banner[data-v-33bced4a] {
    margin: 0;
    padding: 0;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_arrow[data-v-33bced4a] {
    /*background-color: red;*/
    width: 3vw;
    height: 3vw;
    padding: 3vw;
    padding-left: 1vw;
}
.im_arrow[data-v-33bced4a]:hover {
    -webkit-filter: brightness(50);
            filter: brightness(50);
}
.dv_arrow_container[data-v-33bced4a] {
    width: 80%;
    height: 6vw;
    display: inline-block;
    position: absolute;
    left: 10vw;
    margin-top: 7.5vw;
    z-index: 30;
}
.div_arrow[data-v-33bced4a] {
    /*background-color: red;*/
    /*position: absolute;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 2;
    height: 100%;
}
.img_container[data-v-33bced4a] {
    width: 100%;
    height: 100%;
    /*display: flex;*/
    /*text-align: center;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    background-position: center;
    /*background-size: 100% 100%;*/
    background-size: cover;
}
.slider[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.slide1[data-v-33bced4a], .slide2[data-v-33bced4a] {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: 0.6s ease;
    transition: 0.6s ease;
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
}
.slide1.active[data-v-33bced4a], .slide2.active[data-v-33bced4a] {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
.slide1.active ~ .slide1[data-v-33bced4a] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}
.slide2.active ~ .slide2[data-v-33bced4a] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}
.slide1[data-v-33bced4a], .slide2[data-v-33bced4a] {
    text-align: left;
    color: white;
}

/************ BLOG **************/
.root_blog[data-v-33bced4a] {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: var(--light-yellow);
}
.blog1[data-v-33bced4a] {
    margin-top: 15vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 80%;
    /*background-color: pink;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.blog1_p1[data-v-33bced4a] {
    width: 59%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog1_p2[data-v-33bced4a] {
    width: 37%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: blue;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.dv_blog1_inspira[data-v-33bced4a] {
    margin-top: 5vh;
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.tx_texto_inspira[data-v-33bced4a], .tx_inspira[data-v-33bced4a], .tx_autor[data-v-33bced4a] {
    color: var(--dark-brown);
}
.tx_inspira[data-v-33bced4a] {
    /*background-color: var(--light-yellow);*/
    font-family: 'Hanie';
    font-size: 4vw;
    position: relative;
    /*transform: translate(-50%, -50%);*/
    /*--webkit-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    bottom: -2vh;
}
.dv_blog1_inspira_title[data-v-33bced4a] {
    background-color: var(--light-yellow);
    padding-right: 1vw;
    margin-top: -4vw;
    margin-left: -0.1vw;
}
.tx_texto_inspira[data-v-33bced4a] {
    -ms-flex-item-align: center;
        align-self: center;
    font-family: 'NomineeMediumExtended';
    width: 80%;
    font-size: 1.2vw;
    text-align: left;
    margin-top: 2vh;
}
.tx_autor[data-v-33bced4a] {
    -ms-flex-item-align: center;
        align-self: center;
    font-family: 'NomineeMediumExtended';
    width: 80%;
    margin-top: 2vh;
    font-size: 1vw;
    text-align: left;
    margin-bottom: 2vh;
}
.dv_blog1_black[data-v-33bced4a] {
    margin-top: 3vh;
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
}
.tx_blog1_destaque[data-v-33bced4a] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    background-color: var(--dark-gray);
    font-size: 1.5vw;
    padding: 2vh;
}
.tx_blog1_subtitle[data-v-33bced4a] {
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    text-align: left;
}
.tx_blog1_subtitle_dest[data-v-33bced4a] {
    font-family: 'NomineeMediumExtended';
    color: var(--dark-gray-font);
    background-color: white;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.3vw;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    height: 8vh;
}
.dv_semana_container[data-v-33bced4a] {
    margin-top: 1vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    color: var(--light-yellow);
    width: 90%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-item-align: center;
        align-self: center;
    margin-bottom: 1.3vh;
}
.tx_semana[data-v-33bced4a], .tx_semana_mar[data-v-33bced4a] {
    margin-top: 0.2vh;
    font-size: 0.8vw;
}
.tx_semana_mar[data-v-33bced4a] {
    color: var(--light-yellow);
    -ms-flex-item-align: center;
        align-self: center;
    font-size: 0.8vw;
    margin-left: 1vw;
}
.tx_semana_t[data-v-33bced4a] {
    font-family: 'NomineeMedium';
    font-size: 1.3vw;
}
.im_semana_t[data-v-33bced4a] {
    height: 1.4vw;
    -o-object-fit: contain;
       object-fit: contain;
    /*background-color: red;*/
}
.blog2[data-v-33bced4a] {
    margin-top: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 80%;
    /*background-color: pink;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.tx_blog2_title[data-v-33bced4a] {
    font-family: 'NomineeMediumExtended';
    width: 100%;
    height: 8vh;
    background-color: var(--light-gray-font);
    color: white;
    font-size: 2.7vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    white-space: pre;
    margin-bottom: 2vh;
}
.tx_blog2_subtitle[data-v-33bced4a] {
    background: var(--dark-brown);
    color: var(--light-yellow);
    /*font-family: 'NomineeMedium';*/
    /*display:inline;*/
    font-family: 'NomineeMedium';
    font-size: 1.2vw;
    line-height: 1.8vw;
    /*padding-bottom: -12px;*/
    padding-top: 0.2vw;
    padding-bottom: 0.1vw;
    padding-right: 0.2vw;
    padding-left: 0.2vw;
    max-height: 0.5vw;
}
.dv_blog2_subtitle[data-v-33bced4a] {
    /*display: inline-block;*/
    color: white;
    width: 98%; /* Width to define where line break should appear */
    text-align: left;
    /*background-color: blue;*/
    /*margin-left: 2vw;*/
    margin-top: 1vh;
    margin-bottom: 1vh
}
.tx_blog2_subtitle2[data-v-33bced4a] {
    color: var(--light-yellow);
    font-family: 'NomineeExtraLight';
    /*display:inline;*/
    /*font-size: 0.7vw;*/
    font-size: 0.9vw;
    line-height: 1.2vw;
    /*margin-left: 2vw;*/
    text-align: left;
    display: none;
}
.dv_blog2_evento_container1[data-v-33bced4a] {
    width: 100%;
    overflow-x: scroll;
}
.dv_blog2_evento_container2[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    overflow: hidden;
}
.dv_blog2_evento_p1[data-v-33bced4a] {
    height: 80%;
    width: 100%;
    /*background-color: yellow;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.dv_blog2_evento_p2[data-v-33bced4a] {
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;

    -webkit-box-direction: normal;

        -ms-flex-direction: column;

            flex-direction: column;
    height: 20%;
    /*background-color: red;*/
    width: 80%;
    overflow-y: hidden;
}
.dv_blog2_evento_im_mask[data-v-33bced4a] {
    width: 100%;
    height: 3%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: -2%
}
.dv_blog2_evento[data-v-33bced4a] {
    width: 18vw;
    height: 20vw;
    /*max-height: 20vw;*/
    /*background-color: blue;*/
    background-color: var(--dark-gray);
    margin-right: 2vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: hidden;
    padding-bottom: 2vh;
    /*background-color: red;*/
}
.dv_blog2_evento:hover > .dv_blog2_evento_p1[data-v-33bced4a] {
    /*display: none;*/
    height: 0;
    width: 100%;/* Adicionado 27/10/2021 */
    -webkit-transition: height .3s linear;
    transition: height .3s linear;
}
.dv_blog2_evento:hover > .dv_blog2_evento_p2[data-v-33bced4a] {
    height: 100%;
    -webkit-transition: height .3s linear;
    transition: height .3s linear;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_blog2_evento:hover .dv_blog2_evento_im_mask[data-v-33bced4a] {
    display: none;
}
.dv_blog2_evento:hover .tx_blog2_subtitle[data-v-33bced4a] {
    display: none;
}
.dv_blog2_evento:hover .tx_blog2_subtitle2[data-v-33bced4a] {
    display: unset;
}
.blog3[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    /*background-color: red;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 80%;
    height: 60vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.blog3_p1[data-v-33bced4a] {
    width: 65%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog3_p2[data-v-33bced4a] {
    width: 32%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog3_p1_noticia[data-v-33bced4a] {
    width: 92%;
    min-height: var(--height-news);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 2vh;
    cursor: pointer;
}
.blog3_p1_container[data-v-33bced4a] {
    width: 100%;
    height: 100%;
    background-color: var(--dark-brown);
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 3vh;
}
.blog3_p1_im[data-v-33bced4a] {
    width: 27%;
    min-width: 27%;
    /*height: 100%;*/
    height: var(--height-news);
    position: relative;
    -o-object-fit: cover;
       object-fit: cover;
}
.blog3_p1_subcont[data-v-33bced4a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding-right: 2%;
    height: var(--height-news);
    /*position: relative;*/
    border-color: var(--light-gray-font);
    border-style: solid;
    border-width: 1px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-left: 2vw;
}
.blog3_tx1[data-v-33bced4a] {
    color: white;
    font-family: 'NomineeMedium';
    font-size: 1vw;
    text-align: left;
}
.blog3_tx2[data-v-33bced4a] {
    color: var(--light-yellow);
    font-family: 'NomineeExtraLight';
    font-size: 0.8vw;
    text-align: left;
    max-lines: 3;
    overflow-y: hidden;
}
.blog4[data-v-33bced4a] {
    width: 80%;
    height: 60vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding-right: 2vw;
    padding-left: 2vw;
    /*padding-bottom: 2vw;*/
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.blog4_tx_title[data-v-33bced4a] {
    font-family: 'Hanie';
    font-size: 4vw;
    color: var(--dark-brown);
    line-height: 4vw;
    background-color: var(--light-yellow);
    /*background-color: blue;*/
    margin-top: -1vw;
    /*margin-bottom: -2vw;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 1vw;
}
.blog4_dv_container[data-v-33bced4a] {
    /*margin-bottom: -4vh;*/
    /*background-color: purple;*/
    width: 100%;
    height: 52vh;
    overflow-x: scroll;
    overflow-y: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.blog4_dv_el[data-v-33bced4a] {
    width: 25vw;
    min-width: 25vw;
    margin-right: 2vw;
}
.videoC[data-v-33bced4a] {
    width: 100%;
    max-height: 55vh;
    /*object-fit: cover;*/
    /*object-fit: scale-down;*/
    -o-object-fit: fill;
       object-fit: fill;
    /*background-color: var(--dark-gray);*/
    background-color: black;
    padding: 0;
    /*height: 440px;*/
    /*display: none;*/
}
.im_selo[data-v-33bced4a] {
    height: 20vh;
    width: 15vh;
    -o-object-fit: cover;
       object-fit: cover;
}
.im_pop[data-v-33bced4a] {
    width: unset;
}

/************ BLOG **************/

/****************** SLIDER BANNER ******************/

/* celular com tella normal/grande */
@media (max-width:1049px)  {
.tx_title_galeria[data-v-33bced4a] {
        margin-top: 9vh;
        margin-bottom: -7vh;
        font-size: 6vw;
}
.im_pop[data-v-33bced4a] {
        width: 110%;
}
.videoC[data-v-33bced4a] {
        background-color: unset;
}

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
.top-bar-item[data-v-33bced4a] {
        display: none;
}
.top-bar[data-v-33bced4a] {
        width: 100vw;
        height: 7vh;
}
.root[data-v-33bced4a] {
        width: 100vw;
        /*max-width: 100vw !important;*/
        /*background-color: red;*/
}
body[data-v-33bced4a] {
        width: 100vw;
        max-width: 100vw !important;
        /*background-color: purple;*/
}
.dv_historia[data-v-33bced4a] {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        height: auto;
        /*overflow-x: scroll;*/
}
.dv_historia_sub1[data-v-33bced4a] {
        width: 80%;
        padding: 0;
}
.dv_historia_sub2[data-v-33bced4a] {
        width: 80%;
        height: auto;
        padding-right: 0;
        margin-bottom: 5vh;
}
.tx_title_historia[data-v-33bced4a] {
        font-size: 7vh;
}
.dv_colum_comodidades[data-v-33bced4a] {
        /*background-color: red;*/
        width: 80%;
}
.dv_colum_comodidades_container[data-v-33bced4a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-x: scroll;*/
        height: auto;
}
.dv_row_comodidades_container[data-v-33bced4a] {
        height: 15vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        /*background-color: red;*/
        width: 93%;
}
.im_ic_comodidades[data-v-33bced4a] {
        margin-right: 1vh;
        width: 4vh;
        height: 4vh;
}
.tx_title_comodidades[data-v-33bced4a] {
        font-size: 8vh;
        margin-bottom: 0;
        /*background-color: yellow;*/
        /*height: 15vh;*/
        margin-bottom: -5vh;
}
.dv_popup_mail[data-v-33bced4a], .dv_popup_mail2[data-v-33bced4a], .dv_popup_mail3[data-v-33bced4a], .dv_popup_checkin[data-v-33bced4a], .dv_popup_image[data-v-33bced4a] {
        width: 90vw;
        max-width: 90vw !important;
        /*background-color: red;*/
        padding: 3vw;
}
.dv_popup_checkin[data-v-33bced4a] {
        width: 95vw;
        max-width: 95vw !important;
        height: 55.23vw;
}
.dv_popup_image[data-v-33bced4a] {
        width: 80vw;
        max-width: 80vw !important;
        padding: 0;
}
.dv_localizacao_container[data-v-33bced4a] {
        width: 100%;
        /*height: 100%;*/
        height: auto;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        /*overflow-x: scroll;*/
}
.dv_localizacao_sub1[data-v-33bced4a] {
        width: 100%;
        height: 80vh;
}
.dv_localizacao_sub2[data-v-33bced4a] {
        width: 100%;
        height: 50vh;
}
.tx_title_gastro[data-v-33bced4a] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        /*margin-bottom: 5vh;*/
        /*background-color: orange;*/
}
.tx_descricao_gastro[data-v-33bced4a] {
        margin-top: -2vh;
        text-align: right;
        padding-right: 2%;
        /*background-color: red;*/
}
.dv_colum_gastronomia_container[data-v-33bced4a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-x: scroll;*/
        /*height: 85vh;*/
        height: auto;
}
.dv_colum_gastronomia[data-v-33bced4a], .dv_colum_gastronomia3[data-v-33bced4a] {
        width: 80%;
        /*height: 60%;*/
        height: 55vh;
}
.dv_colum_gastronomia3[data-v-33bced4a] {
        margin-bottom: 5vh;
}
.dv_row_gastronomia_container[data-v-33bced4a] {
        margin-top: 5vh;
        height: 15vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        width: 95%;
}
.dv_gastronomia[data-v-33bced4a] {
        height: 45vh;
}
.dv_gastronomia_overlay[data-v-33bced4a] {
        height: 45vh;
}
.dv_footbar_container[data-v-33bced4a] {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.dv_footbar_colum0[data-v-33bced4a] {
        width: 40%;
        margin-left: 2vw;
}
.dv_footbar_colum[data-v-33bced4a] {
        width: 40%;
}
.dv_footbar_colum2[data-v-33bced4a] {
        display: none;
}
.dv_acomodacoes_tit_cont[data-v-33bced4a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.tx_acomodacaoes_subtitle[data-v-33bced4a], .tx_acomodacaoes_subtitle_s[data-v-33bced4a] {
        width: 30%;
        font-size: 1.5vh;
        margin-top: 1vh;
        margin-bottom: 1vh;
}
.tx_title_acomoda[data-v-33bced4a] {
        /*width: 100%;*/
        font-size: 7vh;
        /*background-color: red;*/
}
.dv_acomodacoes_container[data-v-33bced4a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-y: scroll;*/
        height: auto;
}
.dv_row_acomodacoes_container[data-v-33bced4a] {
        width: 95%;
}
.dv_row_acomodacoes_container_ft[data-v-33bced4a], .dv_row_comodidades_container_ft[data-v-33bced4a], .dv_row_gastro_container_ft[data-v-33bced4a] {
        width: 100%;
}
.dv_acomodacoes_sub1[data-v-33bced4a] {
        width: 100%;
        height: 50vh;
}
.dv_descricao_acomoda[data-v-33bced4a] {
        width: 100%;
        height: 60vh;
}
.tx_descricao_acomoda[data-v-33bced4a] {
        height: 60vh !important;
}
.dv_reservas_container[data-v-33bced4a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*height: 43vh;*/
        /*height: 80vh;*/
        /*overflow-y: scroll;*/
        height: auto;
        padding-top: 2vh;
}
.dv_colum_reservas[data-v-33bced4a] {
        width: 80%;
        height: 45vh;
}
.dv_box_calendar[data-v-33bced4a] {
        height: 35vh;
}
.sel_reservas[data-v-33bced4a] {
        width: 10vw;
}
.sel_reservas2[data-v-33bced4a] {
        width: 37vw;
}
.dv_reservas_tit_cont[data-v-33bced4a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-top: 2vh;
        height: 15vh;
}
.dv_reservas_divisor[data-v-33bced4a], .dv_reservas_texto[data-v-33bced4a] {
        display: none;
}
.tx_title_reservas[data-v-33bced4a] {
        width: 100%;
        font-size: 10vh;
}
.tx_title_newsletter[data-v-33bced4a] {
        font-size: 7vh;
}
.dv_gastro_divisor[data-v-33bced4a] {
        display: none;
}
.historia[data-v-33bced4a], .comodidades[data-v-33bced4a], .localizacao[data-v-33bced4a], .gastronomia[data-v-33bced4a], .acomodacoes[data-v-33bced4a], .reservas[data-v-33bced4a], .faleconosco[data-v-33bced4a], .newsletter[data-v-33bced4a] {
        height: auto;
}
.dv_home_selos[data-v-33bced4a] {
        bottom: 2vh;
        right: 5vw;
}
.im_selo[data-v-33bced4a] {
        height: 10vh;
        width: 7.5vh;
        -o-object-fit: cover;
           object-fit: cover;
}
.dv_descricao_acomoda[data-v-33bced4a]::-webkit-scrollbar
    {
        width: 3vw;
}
.popup_overlay2[data-v-33bced4a] {
        display: none;
}

    /*  Blog  */
.blog1[data-v-33bced4a] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-top: 10vh;
}
.blog1_gal[data-v-33bced4a] {
        margin-top: 5vh;
}
.blog1_p1[data-v-33bced4a] {
        width: 100%;
}
.blog1_p2[data-v-33bced4a] {
        width: 100%;
}
.tx_blog2_title[data-v-33bced4a] {
        font-size: 2vw;
}
.tx_blog1_subtitle_dest[data-v-33bced4a] {
        font-size: 2.2vw;
}
.tx_semana_t[data-v-33bced4a] {
        font-size: 3vw;
}
.tx_semana[data-v-33bced4a] {
        font-size: 2vw;
}
.im_semana_t[data-v-33bced4a] {
        height: 3.5vw;
}
.tx_semana_mar[data-v-33bced4a] {
        font-size: 2vw;
}
.tx_inspira[data-v-33bced4a] {
        font-size: 10vw;
        bottom: -1vh;
}
.dv_blog1_inspira_title[data-v-33bced4a] {
        margin-top: -8vw;
        margin-left: -0.7vw;
}
.tx_texto_inspira[data-v-33bced4a] {
        font-size: 2.5vw;
}
.tx_autor[data-v-33bced4a] {
        font-size: 2vw;
}
.blog3[data-v-33bced4a] {
        /*height: 100vh;*/
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: auto;
}
.blog3_p1[data-v-33bced4a] {
        width: 100%;
        height: auto;
        /*height: 50vh;*/
}
.blog3_p2[data-v-33bced4a] {
        margin-top: 2vh;
        width: 100%;
        height: 48vh;
}
.blog3_tx1[data-v-33bced4a] {
        font-size: 2vw;
}
.blog3_tx2[data-v-33bced4a] {
        font-size: 1.8vw;
}
.tx_blog1_destaque[data-v-33bced4a] {
        font-size: 2.5vw;
}
.blog3_p1_noticia[data-v-33bced4a] {
        min-height: var(--height-news_m);
}
.blog3_p1_im[data-v-33bced4a] {
        height: var(--height-news_m);
}
.blog3_p1_subcont[data-v-33bced4a] {
        height: var(--height-news_m);
}
.dv_blog2_evento[data-v-33bced4a] {
        width: 40vw;
        height: 50vw;
}
.dv_blog2_subtitle[data-v-33bced4a] {
        width: 100%;
        margin-top: 0;
}
.tx_blog2_subtitle[data-v-33bced4a] {
        font-size: 2.7vw;
        line-height: 2.7vw;
        padding-top: 0.4vh;
        padding-bottom: 0.1vh;
}
.tx_blog2_subtitle2[data-v-33bced4a] {
        font-size: 2.3vw;
        line-height: 2.6vw;
}
.im_arrow[data-v-33bced4a] {
        width: 8vw;
        height: 8vw;
        padding: 8vw;
        padding-left: 1vw;
}
.dv_arrow_container[data-v-33bced4a] {
        margin-top: 23vw;
}
.dv_blog2_evento_p2[data-v-33bced4a] {
        height: 6vh;
        vertical-align: top;
}
.tx_popup4[data-v-33bced4a] {
        font-size: 2vh;
}
.blog4_dv_container[data-v-33bced4a] {
        /*background-color: red;*/
        height: 57vh;
}
.blog4_dv_el[data-v-33bced4a] {
        /*width: 40vw;*/
        min-width: 60vw;
}
}

/* celular com tel pequena */
@media (max-width:370px)  {
.dv_row_acomodacoes_container[data-v-33bced4a] {
        width: 75%;
}
.dv_row_comodidades_container[data-v-33bced4a] {
        width: 75%;
}
.dv_row_gastronomia_container[data-v-33bced4a] {
        width: 88%;
}
}
@media (min-width:1050px) {
    /* big landscape tablets, laptops, and desktops */
.top-bar-item[data-v-33bced4a] {
        display: unset;
}
.dv_historia[data-v-33bced4a] {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
}
.section[data-v-33bced4a] {
        display: none;
}
}
@media (min-width:1900px) {
    /* big screens desktops */
.videoC[data-v-33bced4a] {
        height: 45vh;
}
}











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/*
    DEMO STYLE
*/
p[data-v-10940360] {
    /*font-family: 'Poppins', sans-serif;*/
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}
a[data-v-10940360], a[data-v-10940360]:hover, a[data-v-10940360]:focus {
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.navbar[data-v-10940360] {
    padding: 2.3vw 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}
.navbar-btn[data-v-10940360] {
    -webkit-box-shadow: none;
            box-shadow: none;
    outline: none !important;
    border: none;
}
.line[data-v-10940360] {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar[data-v-10940360] {
    width: 78%;
    position: fixed;
    top: 0;
    left: -78%;
    height: 100vh;
    z-index: 101;
    /*background: #0171bbBB;*/
    /*background-color: red;*/
    background-color: var(--dark-brown-trans);
    /*color: #fff;*/
    color: var(--light-yellow);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow-y: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;

    -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
#sidebar[data-v-10940360]::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
    display: none;
}
#sidebar.active[data-v-10940360] {
    left: 0;
}
#dismiss[data-v-10940360] {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #549CCB;
    position: absolute;
    top: 20px;
    left: 2.3vw;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.overlay[data-v-10940360] {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(10, 10, 10, 0.6);
    z-index: 98;
    display: none;
}
#sidebar .sidebar-header[data-v-10940360] {
    padding: 20px;
    background-color: var(--dark-brown);
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
#sidebar ul.components[data-v-10940360] {
    padding: 20px 0;
    /*border-bottom: 1px solid #47748b;*/
}
#sidebar ul p[data-v-10940360] {
    color: #fff;
    padding: 10px;
}
#sidebar ul li a[data-v-10940360] {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a[data-v-10940360]:hover {
    /*color: #0171bb;*/
    color: var(--dark-brown);
    background: #fff;
}
#sidebar ul li.active > a[data-v-10940360], a[aria-expanded="true"][data-v-10940360] {
}
a[data-toggle="collapse"][data-v-10940360] {
    position: relative;
}
a[aria-expanded="false"][data-v-10940360]::before, a[aria-expanded="true"][data-v-10940360]::before {
    content: '\E259';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 1.0em;
}
a[aria-expanded="true"][data-v-10940360]::before {
    content: '\E260';
}
ul ul a[data-v-10940360] {
    font-size: 1.05em !important;
    padding-left: 2.3vw !important;
}
ul.CTAs[data-v-10940360] {
    padding: 20px;
}
ul.CTAs a[data-v-10940360] {
    text-align: center;
    font-size: 1.0em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content[data-v-10940360] {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}
@media (min-width:320px)  {
    /* smartphones, iPhone, portrait 480x320 phones */
#sidebar[data-v-10940360] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
#sidebar[data-v-10940360] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:521px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
#sidebar[data-v-10940360] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:641px)  {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
#sidebar[data-v-10940360] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:861px)  {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
#sidebar[data-v-10940360] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:1125px) {
    /* big landscape tablets, laptops, and desktops */
#sidebar[data-v-10940360] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:1281px) {
    /* hi-res laptops and desktops */
#sidebar[data-v-10940360] {
        width: 30%;
        left: -30%;
}
}
/*https://codepen.io/jorenerene/pen/vxKbJb*/
/*https://codepen.io/bennettfeely/pen/twbyA*/
.navicon-button[data-v-10940360] {
    display: inline-block;
    padding: 1.0625rem 0.5rem;
    -webkit-transition: 0.15s;
    transition: 0.15s;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 0.8;
    /*background-color: red;*/
}
.navicon-button .navicon[data-v-10940360]:before, .navicon-button .navicon[data-v-10940360]:after {
    -webkit-transition: 0.15s;
    transition: 0.15s;
}
/*.navicon-button:not(:focus):hover > .navicon {*/
/*transition: 0.3s;*/
/*width: 2.5rem;*/
/*!*background-color: red;*!*/
/*}*/
/*.navicon-button:hover {*/
/*transition: 0.3s;*/
/*opacity: 1;*/
/*}*/
/*.navicon-button:hover .navicon:before, .navicon-button:hover .navicon:after {*/
/*transition: 0.15s;*/
/*}*/
/*.navicon-button:hover .navicon:before {*/
/*top: 0.925rem;*/
/*}*/
/*.navicon-button:hover .navicon:after {*/
/*top: -0.925rem;*/
/*}*/
.navicon[data-v-10940360] {
    position: relative;
    width: var(--menu-icon-width_small);
    /*height: 0.3125rem;*/
    height: var(--menu-icon-height);
    /*max-height: var(--height-menu-icon);*/
    background: var(--menu-icon-gray);
    /*background-color: green;*/
    -webkit-transition: 0.5s;
    transition: 0.5s;
    /*border-radius: 2.5rem;*/
    border-radius: 1rem;
}
.navicon[data-v-10940360]:before, .navicon[data-v-10940360]:after {
    display: block;
    content: "";
    /*height: 0.3125rem;*/
    height: var(--menu-icon-height);
    /*max-height: var(--height-menu-icon);*/
    /*min-height: var(--height-menu-icon);*/
    /*height: 0.2rem;*/
    width: var(--menu-icon-width);
    background: var(--menu-icon-gray);
    position: absolute;
    z-index: 998;
    -webkit-transition: 0.3s 0.15s;
    transition: 0.3s 0.15s;
    border-radius: 2rem;
    /*background-color: blue;*/
}
.navicon[data-v-10940360]:before {
    /*top: 0.825rem;*/
    top: var(--menu-icon-spacing);
}
.navicon[data-v-10940360]:after {
    /*top: -0.825rem;*/
    top: calc(-1 * var(--menu-icon-spacing));
}
.open:not(.steps) .navicon[data-v-10940360]:before, .open:not(.steps) .navicon[data-v-10940360]:after {
    top: 0 !important;
}
.open .navicon[data-v-10940360]:before, .open .navicon[data-v-10940360], .open .navicon[data-v-10940360]:after {
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
/* × */
.open.x .navicon[data-v-10940360] {
    background: transparent;
}
.open.x .navicon[data-v-10940360]:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.open.x .navicon[data-v-10940360]:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.open.x .navicon[data-v-10940360] {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.section[data-v-10940360] {
    position: fixed;
    display: inline-block;
    top: 0;
    left: 0;
    /*margin: -42px auto;*/
    margin-top: var(--menu-icon-top);
    margin-left: 1vw;
    /*background: red;*/
    /*overflow: hidden;*/
    z-index: 100000;
    /* Smoother animations */
}
.section *[data-v-10940360], .section *[data-v-10940360]:before, .section *[data-v-10940360]:after {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
@media (min-width:320px)  {
}
@media (min-width:641px)  {
    /*Botei esso aqui, pois quando tava clicando no botao do menu no celular, quando voltava ele fica expandido (a linha do meio.)*/
.navicon-button:hover > .navicon[data-v-10940360] {
        -webkit-transition: 0.3s;
        transition: 0.3s;
        width: var(--menu-icon-width);

        /*background-color: pink;*/
}
}

.top-bar[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    margin-top: 5vh;
    height: 5vh;
    width: 100%;
    background-color: #000000AA;
    z-index: 10;
}
.top-bar-item[data-v-10940360] {
    color: white;
    font-family: 'NomineeExtraLight';
    cursor: pointer;
}
.top-logo[data-v-10940360], .top-logo_b[data-v-10940360] {
    height: 8vh;
    /*height: 4vh;*/
    /*height: 160%;*/
    /*height: 90%;*/
    margin-top: 1.5vh;
    /*margin-top: 0vh;*/
    /*margin-top: 0.85%;*/
    cursor: pointer;
}
.top-logo_b[data-v-10940360] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(81%, var(--light-yellow)), color-stop(19%, transparent));
    background: linear-gradient(180deg, var(--light-yellow) 81%, transparent 19%);
}
.top-bar-item[data-v-10940360]:hover {
    border-bottom: 1px solid white;
}
.tx_title[data-v-10940360], .tx_title_gastro[data-v-10940360], .tx_title_historia[data-v-10940360], .tx_title_localizacao[data-v-10940360], .tx_title_comodidades[data-v-10940360], .tx_title_acomoda[data-v-10940360], .tx_title_reservas[data-v-10940360], .tx_title_newsletter[data-v-10940360] {
    color: black;
    font-size: 11vh;
    font-family: 'Hanie';
}
.tx_title_historia[data-v-10940360] {
    font-size: 9vh;
    text-align: left;
}
.tx_title_localizacao[data-v-10940360] {
    color: var(--light-yellow);
    margin-bottom: 2vh;
    font-size: 7vh;
}
.tx_title_comodidades[data-v-10940360] {
    color: var(--light-brown);
    margin-top: -2vh;
    margin-bottom: 0vh;
    height: 20vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    /*background-color: blueviolet;*/
}
.tx_title_acomoda[data-v-10940360] {
    color: var(--dark-brown);
    margin-bottom: -5vh;
    font-size: 8vh;
    /*width: 100%;*/
    /*background-color: yellow;*/
}
.tx_title_reservas[data-v-10940360] {
    color: var(--dark-brown-font);
    margin-bottom: -2vh;
    font-size: 9vh;
}
.tx_descricao[data-v-10940360], .tx_descricao_gastro[data-v-10940360], .tx_subtitle_acomoda[data-v-10940360], .tx_subtitle_comodidade[data-v-10940360] {
    color: var(--light-gray-font);
    font-size: 2.2vh;
    line-height: 4.5vh;
    font-family: 'NomineeMediumExtended';
    text-align: left;
}
.tx_title_galeria[data-v-10940360] {
    font-size: 5vh;
    margin-top: 15vh;
    margin-bottom: -10vh;
    color: var(--light-brown);
}
.tx_descricao_gastro[data-v-10940360], .tx_subtitle_acomoda[data-v-10940360], .tx_subtitle_comodidade[data-v-10940360] {
    font-size: 1.6vh;
    line-height: 3.5vh;
}
.tx_comodidades_title[data-v-10940360] {
    font-family: 'NomineeExtraLight';
    color: white;
    font-size: 3vh;
}
.tx_subtitle_comodidade[data-v-10940360] {
    text-align: right;
}
.tx_comodidades_desc[data-v-10940360] {
    font-family: 'NomineeMediumExtended';
    color: white;
    font-size: 2vh;
    font-size: 1.8vh;
    line-height: 3vh;
}
.tx_endereco[data-v-10940360] {
    font-family: 'NomineeMediumExtended';
    color: var(--dark-gray-font);
    font-size: 2.5vh;
    line-height: 4vh;
}
.tx_gastronomia_title[data-v-10940360], .tx_gastronomia_title_s[data-v-10940360] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    font-size: 2.5vh;
    line-height: 7vh;
    cursor: pointer;
    white-space: pre;
}
.tx_gastronomia_title_s[data-v-10940360] {
    border-bottom: 1px solid var(--light-brown);
}

/*.tx_gastronomia_title:hover {*/

/*border-bottom: 1px solid var(--light-brown);*/

/*}*/
.tx_social_gastronomia[data-v-10940360] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    font-size: 2.5vh;
    line-height: 4vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}
.tx_social_gastronomia[data-v-10940360]:hover {
    border-bottom: 2px solid var(--light-brown);
}
.tx_descricao_acomoda[data-v-10940360], .tx_descricao_acomoda_topics[data-v-10940360] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    width: 70%;
    /*white-space: pre;*/
    /*background-color: red;*/
    text-align: left;
    font-size: 1.7vh;
    line-height: 4vh;
    /*display: flex;*/
}
.tx_descricao_acomoda[data-v-10940360] {
    white-space: unset;
    /*margin-bottom: 2vh;*/
    /*margin-top: 5vh;*/
    font-size: 2vh;
    line-height: 5vh;
    /*background-color: red;*/
    /*min-height: 65vh;*/
    text-align: left;
    height: 70vh !important;
    min-height: 70vh !important;
    /*text-align: justify;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*align-content: center;*/
}
.tx_acomodacaoes_subtitle[data-v-10940360], .tx_acomodacaoes_subtitle_s[data-v-10940360] {
    font-family: 'NomineeMediumExtended';
    color: black;
    cursor: pointer;
    margin-bottom: -4vh;
}
.tx_acomodacaoes_subtitle[data-v-10940360]:hover {
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    padding-top: 0.2vw;
    padding-bottom: 0.05vw;
}
.tx_acomodacaoes_subtitle_s[data-v-10940360] {
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    padding-top: 0.2vw;
    padding-bottom: 0.05vw;
}
.im_acomodacoes[data-v-10940360] {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.dv_descricao_acomoda[data-v-10940360] {
    width: 30%;
    height: 70vh;
    background-color: var(--dark-brown);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: scroll;
}

/*background do scroll bar*/
.dv_descricao_acomoda[data-v-10940360]::-webkit-scrollbar-track
{
    /*-webkit-box-shadow: inset 0 0 6px rgba(1,0,0,0.3);*/
    -webkit-box-shadow: none;
    border-radius: 10px;
    /*background-color: #FF0000;*/
    background-color: transparent;
}

/*background do scroll bar*/
.dv_descricao_acomoda[data-v-10940360]::-webkit-scrollbar
{
    width: 1vw;
    /*background-color: #FF00F5;*/
    background-color: transparent;
}

/* FORE GROUND do scroll bar*/
.dv_descricao_acomoda[data-v-10940360]::-webkit-scrollbar-thumb
{
    border-radius: 8px;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,1);*/
    -webkit-box-shadow: none;
    background-color: var(--light-yellow);
    /*background-color: red;*/
}
.root[data-v-10940360] {
    width: 100%;
    height: 100vh;
    display: block;
    /*background-color: red;*/
}
.im_slider[data-v-10940360] {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.im_historia[data-v-10940360] {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_ic_comodidades[data-v-10940360] {
    margin-right: 0.5vw;
    width: 2vw;
    height: 2vw;
}
.im_gastronomia[data-v-10940360] {
    width: 100%;
    height: 63vh;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 1;
}
.dv_im_container[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: yellow;*/
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.im_galery[data-v-10940360] {
    width: 23vw;
    height: 18vw;
    -o-object-fit: cover;
       object-fit: cover;
    /*background-color: red;*/
    padding: 1vw;
}
.im_social[data-v-10940360] {
    /*width: 2.5vh;*/
    height: 2.8vh;
    margin: 0.3vh;
    -o-object-fit: contain;
       object-fit: contain;
}
.dv_gastronomia_overlay[data-v-10940360] {
    width: 100%;
    height: 63vh;
    /*display: flex;*/
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #000000CC;
    z-index: 10;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_gastronomia[data-v-10940360] {
    width: 100%;
    height: 63vh;
    background-image: url('/static/imgs/im_rest01.jpg');
    background-size: cover;
    background-position: center;
    /*cursor: pointer;*/
}

/*.dv_gastronomia:hover > .dv_gastronomia_overlay {*/

/*display: flex;*/

/*}*/
.dv_colum_gastronomia[data-v-10940360], .dv_colum_gastronomia3[data-v-10940360] {
    width: 19.5%;
    height: 100%;
    /*background-color: red;*/
    /*margin-bottom: 1vh;*/
}
.dv_colum_gastronomia:hover .tx_gastronomia_title[data-v-10940360] {
    border-bottom: 1px solid var(--light-brown);
}
.dv_colum_gastronomia3:hover .tx_gastronomia_title[data-v-10940360] {
    border-bottom: 1px solid var(--light-brown);
}
.dv_colum_gastronomia:hover .dv_gastronomia_overlay[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.dv_colum_gastronomia3:hover .dv_gastronomia_overlay[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.dv_colum_gastronomia_container[data-v-10940360] {
    width: 100%;
    height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.dv_row_gastronomia_container[data-v-10940360] {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
         -ms-flex-direction: row;
             flex-direction: row;
     -webkit-box-align: center;
         -ms-flex-align: center;
             align-items: center;
     height: 8vh;
}
.dv_gastro_divisor[data-v-10940360] {
    width: 1px;
    height: 100%;
    background-color: var(--light-brown);
    margin-right: 1vw;
    margin-left: 1vw;
}
.home[data-v-10940360], .historia[data-v-10940360], .comodidades[data-v-10940360], .localizacao[data-v-10940360], .gastronomia[data-v-10940360], .acomodacoes[data-v-10940360], .reservas[data-v-10940360], .footbar[data-v-10940360], .faleconosco[data-v-10940360], .newsletter[data-v-10940360] {
    height: 100vh;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.home[data-v-10940360] {
    /*display: unset;*/
    height: 100%;
    /*background-color: blue;*/
}
.dv_home_selos[data-v-10940360] {
    position: absolute;
    bottom: 6vh;
    right: 4vw;
    z-index: 0;
    /*background-color: red;*/
}
.historia[data-v-10940360] {
    background-color: var(--light-yellow);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.dv_historia[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: 99vh;
}
.dv_historia_sub1[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 60%;
    max-height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 5vw;
    overflow: no-display;
}
.dv_historia_sub2[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-right: 10vw;
}
.comodidades[data-v-10940360] {
    background-color: var(--dark-gray);
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.localizacao[data-v-10940360] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    background-position: initial;
    /*background-repeat: no-repeat;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-size: cover;
}
.dv_localizacao_container[data-v-10940360] {
    width: 80%;
    height: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.dv_localizacao_sub1[data-v-10940360] {
    height: 100%;
    width: 40%;
    background-color: var(--light-yellow);
}
.dv_localizacao_sub2[data-v-10940360] {
    height: 100%;
    width: 60%;
}
.gastronomia[data-v-10940360] {
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.acomodacoes[data-v-10940360] {
    background-color: var(--light-yellow);
}
.dv_acomodacoes_tit_cont[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 20vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.dv_acomodacoes_container[data-v-10940360] {
    width: 100%;
    height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    lex-direction: row;
}
.dv_row_acomodacoes_container_ft[data-v-10940360], .dv_row_comodidades_container_ft[data-v-10940360], .dv_row_gastro_container_ft[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
}
.dv_row_acomodacoes_container_ft[data-v-10940360] {
    /*background-color: yellow;*/
    width: 30%;
}
.dv_row_acomodacoes_container[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    /*background-color: red;*/
    /*padding-right: 10%;*/
    width: 100%;
}
.dv_acomodacoes_sub1[data-v-10940360] {
    width: 70%;
    height: 100%;
}
.reservas[data-v-10940360] {
    background-color: var(--dark-gray);
}
.faleconosco[data-v-10940360], .newsletter[data-v-10940360] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    /*background-color: pink;*/
    background-position: center;
    background-size: cover;
    padding-top: 10vh;
    padding-right: 5%;
    padding-left: 5%;
    max-width: 90vw;
    /*height: 40vh;*/
}
.newsletter[data-v-10940360] {
    height: 90vh;
    padding-top: 20vh
}
.footbar[data-v-10940360] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    background-position: center;
    background-size: cover;
    height: 20vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*background-color: red;*/
}
.dv_footbar_container[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: 100%;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.dv_footbar_colum0[data-v-10940360], .dv_footbar_colum[data-v-10940360], .dv_footbar_colum2[data-v-10940360] {
    width: 20%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.tx_footbar[data-v-10940360], .tx_footbar_desc[data-v-10940360] {
    color: white;
    font-family: 'NomineeExtraLight';
    white-space: pre;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2vh;
    margin-bottom: 2vh;
    /*background-color: blue;*/
}
.tx_footbar_desc[data-v-10940360] {
    font-family: 'NomineeMedium';
    margin-top: 0;
    margin-bottom: 0;
}
.dv_colum_comodidades[data-v-10940360] {
    /*background-color: red;*/
    width: 25%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*align-items: flex-start;*/
    /*align-content: flex-start;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.dv_colum_comodidades_container[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    height: 65vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 4vw;
    padding-left: 4vw;
    /*background-color: red;*/
}
.dv_row_comodidades_container[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 8vh;
    margin-top: 8vh;
    /*background-color: red;*/
}
.dv_line_comodidades[data-v-10940360] {
    /*background-color: blue;*/
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    text-align: left;
    margin-bottom: 5vh;
}
.bt_enviar[data-v-10940360] {
    margin-top: .5vh;
    color: white;
    border-width: 2px;
    border-style: solid;
    font-size: 1.7vh;
    display: inline-block;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    cursor: pointer;
}
.popup_overlay[data-v-10940360], .popup_overlay2[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100%;
    background-color: #000000AA;
    z-index: 10;
}
.popup_overlay2[data-v-10940360] {
    position: relative;
    margin-top: -100vh;
}
.dv_popup_mail[data-v-10940360], .dv_popup_mail2[data-v-10940360], .dv_popup_mail3[data-v-10940360] {
    background-color: var(--dark-brown-font);
    width: 30%;
    height: 30vh;
    padding: 2vh;
}
.dv_popup_mail[data-v-10940360] {
    display: block;
    width: 620px;
    height: 370px;
}
.dv_popup_mail2[data-v-10940360] {
    background-color: var(--light-gray-font);
}
.dv_popup_image[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 50vh;
    cursor: pointer;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_popup_checkin[data-v-10940360] {
    width: 30%;
    height: 30vh;
    background-image: url("/static/imgs/pop_checkin2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.dv_popup_mail3[data-v-10940360] {
    width: 25%;
    height: 25vh;
    background-color: var(--light-gray-font2);
}
.im_whats_over[data-v-10940360] {
    width: 80px;
    height: 80px;
    width: 8vh;
    height: 8vh;
    position: fixed;
    z-index: 10;
    bottom: 14vh;
    right: 2.5vh;
    cursor: pointer;
}
.tx_popup1[data-v-10940360], .tx_popup2[data-v-10940360], .tx_popup2_2[data-v-10940360] {
    font-family: 'NomineeExtraLight';
    font-size: 4vh;
    color: var(--light-yellow);
}
.tx_popup2[data-v-10940360], .tx_popup2_2[data-v-10940360] {
    font-family: 'NomineeMedium';
    padding-top: 0.5vh;
    padding-left: 0.3vh;
    background-color: var(--light-yellow);
    color: var(--dark-brown-font);
}
.tx_popup2_2[data-v-10940360] {
    color: var(--light-gray-font);
}
.tx_popup3[data-v-10940360], .tx_popup4[data-v-10940360] {
    font-family: 'NomineeMedium';
    font-size: 2vh;
    color: var(--light-yellow);
}
.tx_popup4[data-v-10940360] {
    font-size: 0.9vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: 'NomineeMediumExtended';
}
.ipt_popup[data-v-10940360] {
    width: 100%;
    height: 3vh;
    font-size: 2vh;
    text-align: center;
    color: var(--dark-brown-font);
}
.dv_colum_reservas[data-v-10940360] {
    width: 25%;
    height: 100%;
}
.dv_reservas_container[data-v-10940360] {
    width: 100%;
    height: 60vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    padding-top: 10vh;
}
.dv_reservas_tit_cont[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 9vh;
    margin-top: 10vh;
}
.dv_reservas_divisor[data-v-10940360] {
    width: 1px;
    height: 100%;
    background-color: var(--dark-brown-font);
    margin-right: 1vw;
    margin-left: 1vw;
}
.dv_reservas_texto[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.tx_reservas1[data-v-10940360], .tx_reservas2[data-v-10940360], .tx_reservas3[data-v-10940360] {
    font-family: 'NomineeMedium';
    color: var(--light-brown);
    font-size: 3vh;
}
.tx_reservas2[data-v-10940360] {
    background-color: var(--light-brown);
    color: var(--light-yellow);
    padding: 1vh;
    cursor: pointer;
}
.tx_reservas3[data-v-10940360] {
    /*margin-top: 1vh;*/
    font-size: 3.5vh;
    line-height: 8vh;
    margin-right: 2vw;
    margin-left: 2vw;
}
.dv_box_calendar[data-v-10940360] {
    border-style: solid;
    border-width: 2px;
    border-color: var(--light-brown);
    height: 40vh;
    padding-top: 1vh;
    /*background-color: red;*/
}
.sel_reservas[data-v-10940360], .sel_reservas2[data-v-10940360] {
    border:0px;
    outline:0px;
    border: none;
    scroll-behavior: smooth;
    background-color: white;
    -webkit-appearance:none;
    font-family: 'NomineeMedium';
    font-size: 2vh;
    color: white;
    background-color: var(--dark-gray);
    border-color: var(--light-brown);
    border-style: solid;
    border-width: 2px;
    border-radius: 0;
    width: 3vw;
    padding-top: 1vh;
    padding-left: 1vh;
    padding-bottom: 0.5vh;
}
.sel_reservas2[data-v-10940360] {
    width: 10vw;
}
.tb_reserva[data-v-10940360], .tb_reserva_s[data-v-10940360], .tb_reserva_i[data-v-10940360] {
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    color: var(--light-yellow);
}

/* Semanas */
.tb_reserva_sem[data-v-10940360] {
    color: var(--light-brown);
    font-size: 1.8vh;
}

/* Selecionado */
.tb_reserva_s[data-v-10940360] {
    border-color: var(--light-brown);
}

/* Intervalo */
.tb_reserva_i[data-v-10940360] {
    background-color: black;
    /*border-color: var(--light-brown);*/
}
body[data-v-10940360] {
    padding: 10px;
}

/****************** SLIDER BANNER ******************/
.img_banner[data-v-10940360] {
    margin: 0;
    padding: 0;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_arrow[data-v-10940360] {
    /*background-color: red;*/
    width: 3vw;
    height: 3vw;
    padding: 3vw;
    padding-left: 1vw;
}
.im_arrow[data-v-10940360]:hover {
    -webkit-filter: brightness(50);
            filter: brightness(50);
}
.dv_arrow_container[data-v-10940360] {
    width: 80%;
    height: 6vw;
    display: inline-block;
    position: absolute;
    left: 10vw;
    margin-top: 7.5vw;
    z-index: 30;
}
.div_arrow[data-v-10940360] {
    /*background-color: red;*/
    /*position: absolute;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 2;
    height: 100%;
}
.img_container[data-v-10940360] {
    width: 100%;
    height: 100%;
    /*display: flex;*/
    /*text-align: center;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    background-position: center;
    /*background-size: 100% 100%;*/
    background-size: cover;
}
.slider[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.slide1[data-v-10940360], .slide2[data-v-10940360] {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: 0.6s ease;
    transition: 0.6s ease;
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
}
.slide1.active[data-v-10940360], .slide2.active[data-v-10940360] {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
.slide1.active ~ .slide1[data-v-10940360] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}
.slide2.active ~ .slide2[data-v-10940360] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}
.slide1[data-v-10940360], .slide2[data-v-10940360] {
    text-align: left;
    color: white;
}

/************ BLOG **************/
.root_blog[data-v-10940360] {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: var(--light-yellow);
}
.blog1[data-v-10940360] {
    margin-top: 15vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 80%;
    /*background-color: pink;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.blog1_p1[data-v-10940360] {
    width: 59%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog1_p2[data-v-10940360] {
    width: 37%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: blue;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.dv_blog1_inspira[data-v-10940360] {
    margin-top: 5vh;
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.tx_texto_inspira[data-v-10940360], .tx_inspira[data-v-10940360], .tx_autor[data-v-10940360] {
    color: var(--dark-brown);
}
.tx_inspira[data-v-10940360] {
    /*background-color: var(--light-yellow);*/
    font-family: 'Hanie';
    font-size: 4vw;
    position: relative;
    /*transform: translate(-50%, -50%);*/
    /*--webkit-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    bottom: -2vh;
}
.dv_blog1_inspira_title[data-v-10940360] {
    background-color: var(--light-yellow);
    padding-right: 1vw;
    margin-top: -4vw;
    margin-left: -0.1vw;
}
.tx_texto_inspira[data-v-10940360] {
    -ms-flex-item-align: center;
        align-self: center;
    font-family: 'NomineeMediumExtended';
    width: 80%;
    font-size: 1.2vw;
    text-align: left;
    margin-top: 2vh;
}
.tx_autor[data-v-10940360] {
    -ms-flex-item-align: center;
        align-self: center;
    font-family: 'NomineeMediumExtended';
    width: 80%;
    margin-top: 2vh;
    font-size: 1vw;
    text-align: left;
    margin-bottom: 2vh;
}
.dv_blog1_black[data-v-10940360] {
    margin-top: 3vh;
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
}
.tx_blog1_destaque[data-v-10940360] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    background-color: var(--dark-gray);
    font-size: 1.5vw;
    padding: 2vh;
}
.tx_blog1_subtitle[data-v-10940360] {
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    text-align: left;
}
.tx_blog1_subtitle_dest[data-v-10940360] {
    font-family: 'NomineeMediumExtended';
    color: var(--dark-gray-font);
    background-color: white;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.3vw;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    height: 8vh;
}
.dv_semana_container[data-v-10940360] {
    margin-top: 1vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    color: var(--light-yellow);
    width: 90%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-item-align: center;
        align-self: center;
    margin-bottom: 1.3vh;
}
.tx_semana[data-v-10940360], .tx_semana_mar[data-v-10940360] {
    margin-top: 0.2vh;
    font-size: 0.8vw;
}
.tx_semana_mar[data-v-10940360] {
    color: var(--light-yellow);
    -ms-flex-item-align: center;
        align-self: center;
    font-size: 0.8vw;
    margin-left: 1vw;
}
.tx_semana_t[data-v-10940360] {
    font-family: 'NomineeMedium';
    font-size: 1.3vw;
}
.im_semana_t[data-v-10940360] {
    height: 1.4vw;
    -o-object-fit: contain;
       object-fit: contain;
    /*background-color: red;*/
}
.blog2[data-v-10940360] {
    margin-top: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 80%;
    /*background-color: pink;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.tx_blog2_title[data-v-10940360] {
    font-family: 'NomineeMediumExtended';
    width: 100%;
    height: 8vh;
    background-color: var(--light-gray-font);
    color: white;
    font-size: 2.7vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    white-space: pre;
    margin-bottom: 2vh;
}
.tx_blog2_subtitle[data-v-10940360] {
    background: var(--dark-brown);
    color: var(--light-yellow);
    /*font-family: 'NomineeMedium';*/
    /*display:inline;*/
    font-family: 'NomineeMedium';
    font-size: 1.2vw;
    line-height: 1.8vw;
    /*padding-bottom: -12px;*/
    padding-top: 0.2vw;
    padding-bottom: 0.1vw;
    padding-right: 0.2vw;
    padding-left: 0.2vw;
    max-height: 0.5vw;
}
.dv_blog2_subtitle[data-v-10940360] {
    /*display: inline-block;*/
    color: white;
    width: 98%; /* Width to define where line break should appear */
    text-align: left;
    /*background-color: blue;*/
    /*margin-left: 2vw;*/
    margin-top: 1vh;
    margin-bottom: 1vh
}
.tx_blog2_subtitle2[data-v-10940360] {
    color: var(--light-yellow);
    font-family: 'NomineeExtraLight';
    /*display:inline;*/
    /*font-size: 0.7vw;*/
    font-size: 0.9vw;
    line-height: 1.2vw;
    /*margin-left: 2vw;*/
    text-align: left;
    display: none;
}
.dv_blog2_evento_container1[data-v-10940360] {
    width: 100%;
    overflow-x: scroll;
}
.dv_blog2_evento_container2[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    overflow: hidden;
}
.dv_blog2_evento_p1[data-v-10940360] {
    height: 80%;
    width: 100%;
    /*background-color: yellow;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.dv_blog2_evento_p2[data-v-10940360] {
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;

    -webkit-box-direction: normal;

        -ms-flex-direction: column;

            flex-direction: column;
    height: 20%;
    /*background-color: red;*/
    width: 80%;
    overflow-y: hidden;
}
.dv_blog2_evento_im_mask[data-v-10940360] {
    width: 100%;
    height: 3%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: -2%
}
.dv_blog2_evento[data-v-10940360] {
    width: 18vw;
    height: 20vw;
    /*max-height: 20vw;*/
    /*background-color: blue;*/
    background-color: var(--dark-gray);
    margin-right: 2vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: hidden;
    padding-bottom: 2vh;
    /*background-color: red;*/
}
.dv_blog2_evento:hover > .dv_blog2_evento_p1[data-v-10940360] {
    /*display: none;*/
    height: 0;
    width: 100%;/* Adicionado 27/10/2021 */
    -webkit-transition: height .3s linear;
    transition: height .3s linear;
}
.dv_blog2_evento:hover > .dv_blog2_evento_p2[data-v-10940360] {
    height: 100%;
    -webkit-transition: height .3s linear;
    transition: height .3s linear;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_blog2_evento:hover .dv_blog2_evento_im_mask[data-v-10940360] {
    display: none;
}
.dv_blog2_evento:hover .tx_blog2_subtitle[data-v-10940360] {
    display: none;
}
.dv_blog2_evento:hover .tx_blog2_subtitle2[data-v-10940360] {
    display: unset;
}
.blog3[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    /*background-color: red;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 80%;
    height: 60vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.blog3_p1[data-v-10940360] {
    width: 65%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog3_p2[data-v-10940360] {
    width: 32%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog3_p1_noticia[data-v-10940360] {
    width: 92%;
    min-height: var(--height-news);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 2vh;
    cursor: pointer;
}
.blog3_p1_container[data-v-10940360] {
    width: 100%;
    height: 100%;
    background-color: var(--dark-brown);
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 3vh;
}
.blog3_p1_im[data-v-10940360] {
    width: 27%;
    min-width: 27%;
    /*height: 100%;*/
    height: var(--height-news);
    position: relative;
    -o-object-fit: cover;
       object-fit: cover;
}
.blog3_p1_subcont[data-v-10940360] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding-right: 2%;
    height: var(--height-news);
    /*position: relative;*/
    border-color: var(--light-gray-font);
    border-style: solid;
    border-width: 1px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-left: 2vw;
}
.blog3_tx1[data-v-10940360] {
    color: white;
    font-family: 'NomineeMedium';
    font-size: 1vw;
    text-align: left;
}
.blog3_tx2[data-v-10940360] {
    color: var(--light-yellow);
    font-family: 'NomineeExtraLight';
    font-size: 0.8vw;
    text-align: left;
    max-lines: 3;
    overflow-y: hidden;
}
.blog4[data-v-10940360] {
    width: 80%;
    height: 60vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding-right: 2vw;
    padding-left: 2vw;
    /*padding-bottom: 2vw;*/
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.blog4_tx_title[data-v-10940360] {
    font-family: 'Hanie';
    font-size: 4vw;
    color: var(--dark-brown);
    line-height: 4vw;
    background-color: var(--light-yellow);
    /*background-color: blue;*/
    margin-top: -1vw;
    /*margin-bottom: -2vw;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 1vw;
}
.blog4_dv_container[data-v-10940360] {
    /*margin-bottom: -4vh;*/
    /*background-color: purple;*/
    width: 100%;
    height: 52vh;
    overflow-x: scroll;
    overflow-y: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.blog4_dv_el[data-v-10940360] {
    width: 25vw;
    min-width: 25vw;
    margin-right: 2vw;
}
.videoC[data-v-10940360] {
    width: 100%;
    max-height: 55vh;
    /*object-fit: cover;*/
    /*object-fit: scale-down;*/
    -o-object-fit: fill;
       object-fit: fill;
    /*background-color: var(--dark-gray);*/
    background-color: black;
    padding: 0;
    /*height: 440px;*/
    /*display: none;*/
}
.im_selo[data-v-10940360] {
    height: 20vh;
    width: 15vh;
    -o-object-fit: cover;
       object-fit: cover;
}
.im_pop[data-v-10940360] {
    width: unset;
}

/************ BLOG **************/

/****************** SLIDER BANNER ******************/

/* celular com tella normal/grande */
@media (max-width:1049px)  {
.tx_title_galeria[data-v-10940360] {
        margin-top: 9vh;
        margin-bottom: -7vh;
        font-size: 6vw;
}
.im_pop[data-v-10940360] {
        width: 110%;
}
.videoC[data-v-10940360] {
        background-color: unset;
}

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
.top-bar-item[data-v-10940360] {
        display: none;
}
.top-bar[data-v-10940360] {
        width: 100vw;
        height: 7vh;
}
.root[data-v-10940360] {
        width: 100vw;
        /*max-width: 100vw !important;*/
        /*background-color: red;*/
}
body[data-v-10940360] {
        width: 100vw;
        max-width: 100vw !important;
        /*background-color: purple;*/
}
.dv_historia[data-v-10940360] {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        height: auto;
        /*overflow-x: scroll;*/
}
.dv_historia_sub1[data-v-10940360] {
        width: 80%;
        padding: 0;
}
.dv_historia_sub2[data-v-10940360] {
        width: 80%;
        height: auto;
        padding-right: 0;
        margin-bottom: 5vh;
}
.tx_title_historia[data-v-10940360] {
        font-size: 7vh;
}
.dv_colum_comodidades[data-v-10940360] {
        /*background-color: red;*/
        width: 80%;
}
.dv_colum_comodidades_container[data-v-10940360] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-x: scroll;*/
        height: auto;
}
.dv_row_comodidades_container[data-v-10940360] {
        height: 15vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        /*background-color: red;*/
        width: 93%;
}
.im_ic_comodidades[data-v-10940360] {
        margin-right: 1vh;
        width: 4vh;
        height: 4vh;
}
.tx_title_comodidades[data-v-10940360] {
        font-size: 8vh;
        margin-bottom: 0;
        /*background-color: yellow;*/
        /*height: 15vh;*/
        margin-bottom: -5vh;
}
.dv_popup_mail[data-v-10940360], .dv_popup_mail2[data-v-10940360], .dv_popup_mail3[data-v-10940360], .dv_popup_checkin[data-v-10940360], .dv_popup_image[data-v-10940360] {
        width: 90vw;
        max-width: 90vw !important;
        /*background-color: red;*/
        padding: 3vw;
}
.dv_popup_checkin[data-v-10940360] {
        width: 95vw;
        max-width: 95vw !important;
        height: 55.23vw;
}
.dv_popup_image[data-v-10940360] {
        width: 80vw;
        max-width: 80vw !important;
        padding: 0;
}
.dv_localizacao_container[data-v-10940360] {
        width: 100%;
        /*height: 100%;*/
        height: auto;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        /*overflow-x: scroll;*/
}
.dv_localizacao_sub1[data-v-10940360] {
        width: 100%;
        height: 80vh;
}
.dv_localizacao_sub2[data-v-10940360] {
        width: 100%;
        height: 50vh;
}
.tx_title_gastro[data-v-10940360] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        /*margin-bottom: 5vh;*/
        /*background-color: orange;*/
}
.tx_descricao_gastro[data-v-10940360] {
        margin-top: -2vh;
        text-align: right;
        padding-right: 2%;
        /*background-color: red;*/
}
.dv_colum_gastronomia_container[data-v-10940360] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-x: scroll;*/
        /*height: 85vh;*/
        height: auto;
}
.dv_colum_gastronomia[data-v-10940360], .dv_colum_gastronomia3[data-v-10940360] {
        width: 80%;
        /*height: 60%;*/
        height: 55vh;
}
.dv_colum_gastronomia3[data-v-10940360] {
        margin-bottom: 5vh;
}
.dv_row_gastronomia_container[data-v-10940360] {
        margin-top: 5vh;
        height: 15vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        width: 95%;
}
.dv_gastronomia[data-v-10940360] {
        height: 45vh;
}
.dv_gastronomia_overlay[data-v-10940360] {
        height: 45vh;
}
.dv_footbar_container[data-v-10940360] {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.dv_footbar_colum0[data-v-10940360] {
        width: 40%;
        margin-left: 2vw;
}
.dv_footbar_colum[data-v-10940360] {
        width: 40%;
}
.dv_footbar_colum2[data-v-10940360] {
        display: none;
}
.dv_acomodacoes_tit_cont[data-v-10940360] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.tx_acomodacaoes_subtitle[data-v-10940360], .tx_acomodacaoes_subtitle_s[data-v-10940360] {
        width: 30%;
        font-size: 1.5vh;
        margin-top: 1vh;
        margin-bottom: 1vh;
}
.tx_title_acomoda[data-v-10940360] {
        /*width: 100%;*/
        font-size: 7vh;
        /*background-color: red;*/
}
.dv_acomodacoes_container[data-v-10940360] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-y: scroll;*/
        height: auto;
}
.dv_row_acomodacoes_container[data-v-10940360] {
        width: 95%;
}
.dv_row_acomodacoes_container_ft[data-v-10940360], .dv_row_comodidades_container_ft[data-v-10940360], .dv_row_gastro_container_ft[data-v-10940360] {
        width: 100%;
}
.dv_acomodacoes_sub1[data-v-10940360] {
        width: 100%;
        height: 50vh;
}
.dv_descricao_acomoda[data-v-10940360] {
        width: 100%;
        height: 60vh;
}
.tx_descricao_acomoda[data-v-10940360] {
        height: 60vh !important;
}
.dv_reservas_container[data-v-10940360] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*height: 43vh;*/
        /*height: 80vh;*/
        /*overflow-y: scroll;*/
        height: auto;
        padding-top: 2vh;
}
.dv_colum_reservas[data-v-10940360] {
        width: 80%;
        height: 45vh;
}
.dv_box_calendar[data-v-10940360] {
        height: 35vh;
}
.sel_reservas[data-v-10940360] {
        width: 10vw;
}
.sel_reservas2[data-v-10940360] {
        width: 37vw;
}
.dv_reservas_tit_cont[data-v-10940360] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-top: 2vh;
        height: 15vh;
}
.dv_reservas_divisor[data-v-10940360], .dv_reservas_texto[data-v-10940360] {
        display: none;
}
.tx_title_reservas[data-v-10940360] {
        width: 100%;
        font-size: 10vh;
}
.tx_title_newsletter[data-v-10940360] {
        font-size: 7vh;
}
.dv_gastro_divisor[data-v-10940360] {
        display: none;
}
.historia[data-v-10940360], .comodidades[data-v-10940360], .localizacao[data-v-10940360], .gastronomia[data-v-10940360], .acomodacoes[data-v-10940360], .reservas[data-v-10940360], .faleconosco[data-v-10940360], .newsletter[data-v-10940360] {
        height: auto;
}
.dv_home_selos[data-v-10940360] {
        bottom: 2vh;
        right: 5vw;
}
.im_selo[data-v-10940360] {
        height: 10vh;
        width: 7.5vh;
        -o-object-fit: cover;
           object-fit: cover;
}
.dv_descricao_acomoda[data-v-10940360]::-webkit-scrollbar
    {
        width: 3vw;
}
.popup_overlay2[data-v-10940360] {
        display: none;
}

    /*  Blog  */
.blog1[data-v-10940360] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-top: 10vh;
}
.blog1_gal[data-v-10940360] {
        margin-top: 5vh;
}
.blog1_p1[data-v-10940360] {
        width: 100%;
}
.blog1_p2[data-v-10940360] {
        width: 100%;
}
.tx_blog2_title[data-v-10940360] {
        font-size: 2vw;
}
.tx_blog1_subtitle_dest[data-v-10940360] {
        font-size: 2.2vw;
}
.tx_semana_t[data-v-10940360] {
        font-size: 3vw;
}
.tx_semana[data-v-10940360] {
        font-size: 2vw;
}
.im_semana_t[data-v-10940360] {
        height: 3.5vw;
}
.tx_semana_mar[data-v-10940360] {
        font-size: 2vw;
}
.tx_inspira[data-v-10940360] {
        font-size: 10vw;
        bottom: -1vh;
}
.dv_blog1_inspira_title[data-v-10940360] {
        margin-top: -8vw;
        margin-left: -0.7vw;
}
.tx_texto_inspira[data-v-10940360] {
        font-size: 2.5vw;
}
.tx_autor[data-v-10940360] {
        font-size: 2vw;
}
.blog3[data-v-10940360] {
        /*height: 100vh;*/
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: auto;
}
.blog3_p1[data-v-10940360] {
        width: 100%;
        height: auto;
        /*height: 50vh;*/
}
.blog3_p2[data-v-10940360] {
        margin-top: 2vh;
        width: 100%;
        height: 48vh;
}
.blog3_tx1[data-v-10940360] {
        font-size: 2vw;
}
.blog3_tx2[data-v-10940360] {
        font-size: 1.8vw;
}
.tx_blog1_destaque[data-v-10940360] {
        font-size: 2.5vw;
}
.blog3_p1_noticia[data-v-10940360] {
        min-height: var(--height-news_m);
}
.blog3_p1_im[data-v-10940360] {
        height: var(--height-news_m);
}
.blog3_p1_subcont[data-v-10940360] {
        height: var(--height-news_m);
}
.dv_blog2_evento[data-v-10940360] {
        width: 40vw;
        height: 50vw;
}
.dv_blog2_subtitle[data-v-10940360] {
        width: 100%;
        margin-top: 0;
}
.tx_blog2_subtitle[data-v-10940360] {
        font-size: 2.7vw;
        line-height: 2.7vw;
        padding-top: 0.4vh;
        padding-bottom: 0.1vh;
}
.tx_blog2_subtitle2[data-v-10940360] {
        font-size: 2.3vw;
        line-height: 2.6vw;
}
.im_arrow[data-v-10940360] {
        width: 8vw;
        height: 8vw;
        padding: 8vw;
        padding-left: 1vw;
}
.dv_arrow_container[data-v-10940360] {
        margin-top: 23vw;
}
.dv_blog2_evento_p2[data-v-10940360] {
        height: 6vh;
        vertical-align: top;
}
.tx_popup4[data-v-10940360] {
        font-size: 2vh;
}
.blog4_dv_container[data-v-10940360] {
        /*background-color: red;*/
        height: 57vh;
}
.blog4_dv_el[data-v-10940360] {
        /*width: 40vw;*/
        min-width: 60vw;
}
}

/* celular com tel pequena */
@media (max-width:370px)  {
.dv_row_acomodacoes_container[data-v-10940360] {
        width: 75%;
}
.dv_row_comodidades_container[data-v-10940360] {
        width: 75%;
}
.dv_row_gastronomia_container[data-v-10940360] {
        width: 88%;
}
}
@media (min-width:1050px) {
    /* big landscape tablets, laptops, and desktops */
.top-bar-item[data-v-10940360] {
        display: unset;
}
.dv_historia[data-v-10940360] {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
}
.section[data-v-10940360] {
        display: none;
}
}
@media (min-width:1900px) {
    /* big screens desktops */
.videoC[data-v-10940360] {
        height: 45vh;
}
}


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/*
    DEMO STYLE
*/
p[data-v-b91ef9a8] {
    /*font-family: 'Poppins', sans-serif;*/
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}
a[data-v-b91ef9a8], a[data-v-b91ef9a8]:hover, a[data-v-b91ef9a8]:focus {
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.navbar[data-v-b91ef9a8] {
    padding: 2.3vw 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}
.navbar-btn[data-v-b91ef9a8] {
    -webkit-box-shadow: none;
            box-shadow: none;
    outline: none !important;
    border: none;
}
.line[data-v-b91ef9a8] {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar[data-v-b91ef9a8] {
    width: 78%;
    position: fixed;
    top: 0;
    left: -78%;
    height: 100vh;
    z-index: 101;
    /*background: #0171bbBB;*/
    /*background-color: red;*/
    background-color: var(--dark-brown-trans);
    /*color: #fff;*/
    color: var(--light-yellow);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow-y: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;

    -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
#sidebar[data-v-b91ef9a8]::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
    display: none;
}
#sidebar.active[data-v-b91ef9a8] {
    left: 0;
}
#dismiss[data-v-b91ef9a8] {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #549CCB;
    position: absolute;
    top: 20px;
    left: 2.3vw;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.overlay[data-v-b91ef9a8] {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(10, 10, 10, 0.6);
    z-index: 98;
    display: none;
}
#sidebar .sidebar-header[data-v-b91ef9a8] {
    padding: 20px;
    background-color: var(--dark-brown);
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
#sidebar ul.components[data-v-b91ef9a8] {
    padding: 20px 0;
    /*border-bottom: 1px solid #47748b;*/
}
#sidebar ul p[data-v-b91ef9a8] {
    color: #fff;
    padding: 10px;
}
#sidebar ul li a[data-v-b91ef9a8] {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a[data-v-b91ef9a8]:hover {
    /*color: #0171bb;*/
    color: var(--dark-brown);
    background: #fff;
}
#sidebar ul li.active > a[data-v-b91ef9a8], a[aria-expanded="true"][data-v-b91ef9a8] {
}
a[data-toggle="collapse"][data-v-b91ef9a8] {
    position: relative;
}
a[aria-expanded="false"][data-v-b91ef9a8]::before, a[aria-expanded="true"][data-v-b91ef9a8]::before {
    content: '\E259';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 1.0em;
}
a[aria-expanded="true"][data-v-b91ef9a8]::before {
    content: '\E260';
}
ul ul a[data-v-b91ef9a8] {
    font-size: 1.05em !important;
    padding-left: 2.3vw !important;
}
ul.CTAs[data-v-b91ef9a8] {
    padding: 20px;
}
ul.CTAs a[data-v-b91ef9a8] {
    text-align: center;
    font-size: 1.0em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content[data-v-b91ef9a8] {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}
@media (min-width:320px)  {
    /* smartphones, iPhone, portrait 480x320 phones */
#sidebar[data-v-b91ef9a8] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
#sidebar[data-v-b91ef9a8] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:521px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
#sidebar[data-v-b91ef9a8] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:641px)  {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
#sidebar[data-v-b91ef9a8] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:861px)  {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
#sidebar[data-v-b91ef9a8] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:1125px) {
    /* big landscape tablets, laptops, and desktops */
#sidebar[data-v-b91ef9a8] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:1281px) {
    /* hi-res laptops and desktops */
#sidebar[data-v-b91ef9a8] {
        width: 30%;
        left: -30%;
}
}
/*https://codepen.io/jorenerene/pen/vxKbJb*/
/*https://codepen.io/bennettfeely/pen/twbyA*/
.navicon-button[data-v-b91ef9a8] {
    display: inline-block;
    padding: 1.0625rem 0.5rem;
    -webkit-transition: 0.15s;
    transition: 0.15s;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 0.8;
    /*background-color: red;*/
}
.navicon-button .navicon[data-v-b91ef9a8]:before, .navicon-button .navicon[data-v-b91ef9a8]:after {
    -webkit-transition: 0.15s;
    transition: 0.15s;
}
/*.navicon-button:not(:focus):hover > .navicon {*/
/*transition: 0.3s;*/
/*width: 2.5rem;*/
/*!*background-color: red;*!*/
/*}*/
/*.navicon-button:hover {*/
/*transition: 0.3s;*/
/*opacity: 1;*/
/*}*/
/*.navicon-button:hover .navicon:before, .navicon-button:hover .navicon:after {*/
/*transition: 0.15s;*/
/*}*/
/*.navicon-button:hover .navicon:before {*/
/*top: 0.925rem;*/
/*}*/
/*.navicon-button:hover .navicon:after {*/
/*top: -0.925rem;*/
/*}*/
.navicon[data-v-b91ef9a8] {
    position: relative;
    width: var(--menu-icon-width_small);
    /*height: 0.3125rem;*/
    height: var(--menu-icon-height);
    /*max-height: var(--height-menu-icon);*/
    background: var(--menu-icon-gray);
    /*background-color: green;*/
    -webkit-transition: 0.5s;
    transition: 0.5s;
    /*border-radius: 2.5rem;*/
    border-radius: 1rem;
}
.navicon[data-v-b91ef9a8]:before, .navicon[data-v-b91ef9a8]:after {
    display: block;
    content: "";
    /*height: 0.3125rem;*/
    height: var(--menu-icon-height);
    /*max-height: var(--height-menu-icon);*/
    /*min-height: var(--height-menu-icon);*/
    /*height: 0.2rem;*/
    width: var(--menu-icon-width);
    background: var(--menu-icon-gray);
    position: absolute;
    z-index: 998;
    -webkit-transition: 0.3s 0.15s;
    transition: 0.3s 0.15s;
    border-radius: 2rem;
    /*background-color: blue;*/
}
.navicon[data-v-b91ef9a8]:before {
    /*top: 0.825rem;*/
    top: var(--menu-icon-spacing);
}
.navicon[data-v-b91ef9a8]:after {
    /*top: -0.825rem;*/
    top: calc(-1 * var(--menu-icon-spacing));
}
.open:not(.steps) .navicon[data-v-b91ef9a8]:before, .open:not(.steps) .navicon[data-v-b91ef9a8]:after {
    top: 0 !important;
}
.open .navicon[data-v-b91ef9a8]:before, .open .navicon[data-v-b91ef9a8], .open .navicon[data-v-b91ef9a8]:after {
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
/* × */
.open.x .navicon[data-v-b91ef9a8] {
    background: transparent;
}
.open.x .navicon[data-v-b91ef9a8]:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.open.x .navicon[data-v-b91ef9a8]:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.open.x .navicon[data-v-b91ef9a8] {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.section[data-v-b91ef9a8] {
    position: fixed;
    display: inline-block;
    top: 0;
    left: 0;
    /*margin: -42px auto;*/
    margin-top: var(--menu-icon-top);
    margin-left: 1vw;
    /*background: red;*/
    /*overflow: hidden;*/
    z-index: 100000;
    /* Smoother animations */
}
.section *[data-v-b91ef9a8], .section *[data-v-b91ef9a8]:before, .section *[data-v-b91ef9a8]:after {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
@media (min-width:320px)  {
}
@media (min-width:641px)  {
    /*Botei esso aqui, pois quando tava clicando no botao do menu no celular, quando voltava ele fica expandido (a linha do meio.)*/
.navicon-button:hover > .navicon[data-v-b91ef9a8] {
        -webkit-transition: 0.3s;
        transition: 0.3s;
        width: var(--menu-icon-width);

        /*background-color: pink;*/
}
}

.top-bar[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    margin-top: 5vh;
    height: 5vh;
    width: 100%;
    background-color: #000000AA;
    z-index: 10;
}
.top-bar-item[data-v-b91ef9a8] {
    color: white;
    font-family: 'NomineeExtraLight';
    cursor: pointer;
}
.top-logo[data-v-b91ef9a8], .top-logo_b[data-v-b91ef9a8] {
    height: 8vh;
    /*height: 4vh;*/
    /*height: 160%;*/
    /*height: 90%;*/
    margin-top: 1.5vh;
    /*margin-top: 0vh;*/
    /*margin-top: 0.85%;*/
    cursor: pointer;
}
.top-logo_b[data-v-b91ef9a8] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(81%, var(--light-yellow)), color-stop(19%, transparent));
    background: linear-gradient(180deg, var(--light-yellow) 81%, transparent 19%);
}
.top-bar-item[data-v-b91ef9a8]:hover {
    border-bottom: 1px solid white;
}
.tx_title[data-v-b91ef9a8], .tx_title_gastro[data-v-b91ef9a8], .tx_title_historia[data-v-b91ef9a8], .tx_title_localizacao[data-v-b91ef9a8], .tx_title_comodidades[data-v-b91ef9a8], .tx_title_acomoda[data-v-b91ef9a8], .tx_title_reservas[data-v-b91ef9a8], .tx_title_newsletter[data-v-b91ef9a8] {
    color: black;
    font-size: 11vh;
    font-family: 'Hanie';
}
.tx_title_historia[data-v-b91ef9a8] {
    font-size: 9vh;
    text-align: left;
}
.tx_title_localizacao[data-v-b91ef9a8] {
    color: var(--light-yellow);
    margin-bottom: 2vh;
    font-size: 7vh;
}
.tx_title_comodidades[data-v-b91ef9a8] {
    color: var(--light-brown);
    margin-top: -2vh;
    margin-bottom: 0vh;
    height: 20vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    /*background-color: blueviolet;*/
}
.tx_title_acomoda[data-v-b91ef9a8] {
    color: var(--dark-brown);
    margin-bottom: -5vh;
    font-size: 8vh;
    /*width: 100%;*/
    /*background-color: yellow;*/
}
.tx_title_reservas[data-v-b91ef9a8] {
    color: var(--dark-brown-font);
    margin-bottom: -2vh;
    font-size: 9vh;
}
.tx_descricao[data-v-b91ef9a8], .tx_descricao_gastro[data-v-b91ef9a8], .tx_subtitle_acomoda[data-v-b91ef9a8], .tx_subtitle_comodidade[data-v-b91ef9a8] {
    color: var(--light-gray-font);
    font-size: 2.2vh;
    line-height: 4.5vh;
    font-family: 'NomineeMediumExtended';
    text-align: left;
}
.tx_title_galeria[data-v-b91ef9a8] {
    font-size: 5vh;
    margin-top: 15vh;
    margin-bottom: -10vh;
    color: var(--light-brown);
}
.tx_descricao_gastro[data-v-b91ef9a8], .tx_subtitle_acomoda[data-v-b91ef9a8], .tx_subtitle_comodidade[data-v-b91ef9a8] {
    font-size: 1.6vh;
    line-height: 3.5vh;
}
.tx_comodidades_title[data-v-b91ef9a8] {
    font-family: 'NomineeExtraLight';
    color: white;
    font-size: 3vh;
}
.tx_subtitle_comodidade[data-v-b91ef9a8] {
    text-align: right;
}
.tx_comodidades_desc[data-v-b91ef9a8] {
    font-family: 'NomineeMediumExtended';
    color: white;
    font-size: 2vh;
    font-size: 1.8vh;
    line-height: 3vh;
}
.tx_endereco[data-v-b91ef9a8] {
    font-family: 'NomineeMediumExtended';
    color: var(--dark-gray-font);
    font-size: 2.5vh;
    line-height: 4vh;
}
.tx_gastronomia_title[data-v-b91ef9a8], .tx_gastronomia_title_s[data-v-b91ef9a8] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    font-size: 2.5vh;
    line-height: 7vh;
    cursor: pointer;
    white-space: pre;
}
.tx_gastronomia_title_s[data-v-b91ef9a8] {
    border-bottom: 1px solid var(--light-brown);
}

/*.tx_gastronomia_title:hover {*/

/*border-bottom: 1px solid var(--light-brown);*/

/*}*/
.tx_social_gastronomia[data-v-b91ef9a8] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    font-size: 2.5vh;
    line-height: 4vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}
.tx_social_gastronomia[data-v-b91ef9a8]:hover {
    border-bottom: 2px solid var(--light-brown);
}
.tx_descricao_acomoda[data-v-b91ef9a8], .tx_descricao_acomoda_topics[data-v-b91ef9a8] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    width: 70%;
    /*white-space: pre;*/
    /*background-color: red;*/
    text-align: left;
    font-size: 1.7vh;
    line-height: 4vh;
    /*display: flex;*/
}
.tx_descricao_acomoda[data-v-b91ef9a8] {
    white-space: unset;
    /*margin-bottom: 2vh;*/
    /*margin-top: 5vh;*/
    font-size: 2vh;
    line-height: 5vh;
    /*background-color: red;*/
    /*min-height: 65vh;*/
    text-align: left;
    height: 70vh !important;
    min-height: 70vh !important;
    /*text-align: justify;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*align-content: center;*/
}
.tx_acomodacaoes_subtitle[data-v-b91ef9a8], .tx_acomodacaoes_subtitle_s[data-v-b91ef9a8] {
    font-family: 'NomineeMediumExtended';
    color: black;
    cursor: pointer;
    margin-bottom: -4vh;
}
.tx_acomodacaoes_subtitle[data-v-b91ef9a8]:hover {
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    padding-top: 0.2vw;
    padding-bottom: 0.05vw;
}
.tx_acomodacaoes_subtitle_s[data-v-b91ef9a8] {
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    padding-top: 0.2vw;
    padding-bottom: 0.05vw;
}
.im_acomodacoes[data-v-b91ef9a8] {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.dv_descricao_acomoda[data-v-b91ef9a8] {
    width: 30%;
    height: 70vh;
    background-color: var(--dark-brown);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: scroll;
}

/*background do scroll bar*/
.dv_descricao_acomoda[data-v-b91ef9a8]::-webkit-scrollbar-track
{
    /*-webkit-box-shadow: inset 0 0 6px rgba(1,0,0,0.3);*/
    -webkit-box-shadow: none;
    border-radius: 10px;
    /*background-color: #FF0000;*/
    background-color: transparent;
}

/*background do scroll bar*/
.dv_descricao_acomoda[data-v-b91ef9a8]::-webkit-scrollbar
{
    width: 1vw;
    /*background-color: #FF00F5;*/
    background-color: transparent;
}

/* FORE GROUND do scroll bar*/
.dv_descricao_acomoda[data-v-b91ef9a8]::-webkit-scrollbar-thumb
{
    border-radius: 8px;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,1);*/
    -webkit-box-shadow: none;
    background-color: var(--light-yellow);
    /*background-color: red;*/
}
.root[data-v-b91ef9a8] {
    width: 100%;
    height: 100vh;
    display: block;
    /*background-color: red;*/
}
.im_slider[data-v-b91ef9a8] {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.im_historia[data-v-b91ef9a8] {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_ic_comodidades[data-v-b91ef9a8] {
    margin-right: 0.5vw;
    width: 2vw;
    height: 2vw;
}
.im_gastronomia[data-v-b91ef9a8] {
    width: 100%;
    height: 63vh;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 1;
}
.dv_im_container[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: yellow;*/
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.im_galery[data-v-b91ef9a8] {
    width: 23vw;
    height: 18vw;
    -o-object-fit: cover;
       object-fit: cover;
    /*background-color: red;*/
    padding: 1vw;
}
.im_social[data-v-b91ef9a8] {
    /*width: 2.5vh;*/
    height: 2.8vh;
    margin: 0.3vh;
    -o-object-fit: contain;
       object-fit: contain;
}
.dv_gastronomia_overlay[data-v-b91ef9a8] {
    width: 100%;
    height: 63vh;
    /*display: flex;*/
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #000000CC;
    z-index: 10;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_gastronomia[data-v-b91ef9a8] {
    width: 100%;
    height: 63vh;
    background-image: url('/static/imgs/im_rest01.jpg');
    background-size: cover;
    background-position: center;
    /*cursor: pointer;*/
}

/*.dv_gastronomia:hover > .dv_gastronomia_overlay {*/

/*display: flex;*/

/*}*/
.dv_colum_gastronomia[data-v-b91ef9a8], .dv_colum_gastronomia3[data-v-b91ef9a8] {
    width: 19.5%;
    height: 100%;
    /*background-color: red;*/
    /*margin-bottom: 1vh;*/
}
.dv_colum_gastronomia:hover .tx_gastronomia_title[data-v-b91ef9a8] {
    border-bottom: 1px solid var(--light-brown);
}
.dv_colum_gastronomia3:hover .tx_gastronomia_title[data-v-b91ef9a8] {
    border-bottom: 1px solid var(--light-brown);
}
.dv_colum_gastronomia:hover .dv_gastronomia_overlay[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.dv_colum_gastronomia3:hover .dv_gastronomia_overlay[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.dv_colum_gastronomia_container[data-v-b91ef9a8] {
    width: 100%;
    height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.dv_row_gastronomia_container[data-v-b91ef9a8] {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
         -ms-flex-direction: row;
             flex-direction: row;
     -webkit-box-align: center;
         -ms-flex-align: center;
             align-items: center;
     height: 8vh;
}
.dv_gastro_divisor[data-v-b91ef9a8] {
    width: 1px;
    height: 100%;
    background-color: var(--light-brown);
    margin-right: 1vw;
    margin-left: 1vw;
}
.home[data-v-b91ef9a8], .historia[data-v-b91ef9a8], .comodidades[data-v-b91ef9a8], .localizacao[data-v-b91ef9a8], .gastronomia[data-v-b91ef9a8], .acomodacoes[data-v-b91ef9a8], .reservas[data-v-b91ef9a8], .footbar[data-v-b91ef9a8], .faleconosco[data-v-b91ef9a8], .newsletter[data-v-b91ef9a8] {
    height: 100vh;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.home[data-v-b91ef9a8] {
    /*display: unset;*/
    height: 100%;
    /*background-color: blue;*/
}
.dv_home_selos[data-v-b91ef9a8] {
    position: absolute;
    bottom: 6vh;
    right: 4vw;
    z-index: 0;
    /*background-color: red;*/
}
.historia[data-v-b91ef9a8] {
    background-color: var(--light-yellow);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.dv_historia[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: 99vh;
}
.dv_historia_sub1[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 60%;
    max-height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 5vw;
    overflow: no-display;
}
.dv_historia_sub2[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-right: 10vw;
}
.comodidades[data-v-b91ef9a8] {
    background-color: var(--dark-gray);
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.localizacao[data-v-b91ef9a8] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    background-position: initial;
    /*background-repeat: no-repeat;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-size: cover;
}
.dv_localizacao_container[data-v-b91ef9a8] {
    width: 80%;
    height: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.dv_localizacao_sub1[data-v-b91ef9a8] {
    height: 100%;
    width: 40%;
    background-color: var(--light-yellow);
}
.dv_localizacao_sub2[data-v-b91ef9a8] {
    height: 100%;
    width: 60%;
}
.gastronomia[data-v-b91ef9a8] {
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.acomodacoes[data-v-b91ef9a8] {
    background-color: var(--light-yellow);
}
.dv_acomodacoes_tit_cont[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 20vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.dv_acomodacoes_container[data-v-b91ef9a8] {
    width: 100%;
    height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    lex-direction: row;
}
.dv_row_acomodacoes_container_ft[data-v-b91ef9a8], .dv_row_comodidades_container_ft[data-v-b91ef9a8], .dv_row_gastro_container_ft[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
}
.dv_row_acomodacoes_container_ft[data-v-b91ef9a8] {
    /*background-color: yellow;*/
    width: 30%;
}
.dv_row_acomodacoes_container[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    /*background-color: red;*/
    /*padding-right: 10%;*/
    width: 100%;
}
.dv_acomodacoes_sub1[data-v-b91ef9a8] {
    width: 70%;
    height: 100%;
}
.reservas[data-v-b91ef9a8] {
    background-color: var(--dark-gray);
}
.faleconosco[data-v-b91ef9a8], .newsletter[data-v-b91ef9a8] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    /*background-color: pink;*/
    background-position: center;
    background-size: cover;
    padding-top: 10vh;
    padding-right: 5%;
    padding-left: 5%;
    max-width: 90vw;
    /*height: 40vh;*/
}
.newsletter[data-v-b91ef9a8] {
    height: 90vh;
    padding-top: 20vh
}
.footbar[data-v-b91ef9a8] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    background-position: center;
    background-size: cover;
    height: 20vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*background-color: red;*/
}
.dv_footbar_container[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: 100%;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.dv_footbar_colum0[data-v-b91ef9a8], .dv_footbar_colum[data-v-b91ef9a8], .dv_footbar_colum2[data-v-b91ef9a8] {
    width: 20%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.tx_footbar[data-v-b91ef9a8], .tx_footbar_desc[data-v-b91ef9a8] {
    color: white;
    font-family: 'NomineeExtraLight';
    white-space: pre;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2vh;
    margin-bottom: 2vh;
    /*background-color: blue;*/
}
.tx_footbar_desc[data-v-b91ef9a8] {
    font-family: 'NomineeMedium';
    margin-top: 0;
    margin-bottom: 0;
}
.dv_colum_comodidades[data-v-b91ef9a8] {
    /*background-color: red;*/
    width: 25%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*align-items: flex-start;*/
    /*align-content: flex-start;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.dv_colum_comodidades_container[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    height: 65vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 4vw;
    padding-left: 4vw;
    /*background-color: red;*/
}
.dv_row_comodidades_container[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 8vh;
    margin-top: 8vh;
    /*background-color: red;*/
}
.dv_line_comodidades[data-v-b91ef9a8] {
    /*background-color: blue;*/
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    text-align: left;
    margin-bottom: 5vh;
}
.bt_enviar[data-v-b91ef9a8] {
    margin-top: .5vh;
    color: white;
    border-width: 2px;
    border-style: solid;
    font-size: 1.7vh;
    display: inline-block;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    cursor: pointer;
}
.popup_overlay[data-v-b91ef9a8], .popup_overlay2[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100%;
    background-color: #000000AA;
    z-index: 10;
}
.popup_overlay2[data-v-b91ef9a8] {
    position: relative;
    margin-top: -100vh;
}
.dv_popup_mail[data-v-b91ef9a8], .dv_popup_mail2[data-v-b91ef9a8], .dv_popup_mail3[data-v-b91ef9a8] {
    background-color: var(--dark-brown-font);
    width: 30%;
    height: 30vh;
    padding: 2vh;
}
.dv_popup_mail[data-v-b91ef9a8] {
    display: block;
    width: 620px;
    height: 370px;
}
.dv_popup_mail2[data-v-b91ef9a8] {
    background-color: var(--light-gray-font);
}
.dv_popup_image[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 50vh;
    cursor: pointer;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_popup_checkin[data-v-b91ef9a8] {
    width: 30%;
    height: 30vh;
    background-image: url("/static/imgs/pop_checkin2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.dv_popup_mail3[data-v-b91ef9a8] {
    width: 25%;
    height: 25vh;
    background-color: var(--light-gray-font2);
}
.im_whats_over[data-v-b91ef9a8] {
    width: 80px;
    height: 80px;
    width: 8vh;
    height: 8vh;
    position: fixed;
    z-index: 10;
    bottom: 14vh;
    right: 2.5vh;
    cursor: pointer;
}
.tx_popup1[data-v-b91ef9a8], .tx_popup2[data-v-b91ef9a8], .tx_popup2_2[data-v-b91ef9a8] {
    font-family: 'NomineeExtraLight';
    font-size: 4vh;
    color: var(--light-yellow);
}
.tx_popup2[data-v-b91ef9a8], .tx_popup2_2[data-v-b91ef9a8] {
    font-family: 'NomineeMedium';
    padding-top: 0.5vh;
    padding-left: 0.3vh;
    background-color: var(--light-yellow);
    color: var(--dark-brown-font);
}
.tx_popup2_2[data-v-b91ef9a8] {
    color: var(--light-gray-font);
}
.tx_popup3[data-v-b91ef9a8], .tx_popup4[data-v-b91ef9a8] {
    font-family: 'NomineeMedium';
    font-size: 2vh;
    color: var(--light-yellow);
}
.tx_popup4[data-v-b91ef9a8] {
    font-size: 0.9vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: 'NomineeMediumExtended';
}
.ipt_popup[data-v-b91ef9a8] {
    width: 100%;
    height: 3vh;
    font-size: 2vh;
    text-align: center;
    color: var(--dark-brown-font);
}
.dv_colum_reservas[data-v-b91ef9a8] {
    width: 25%;
    height: 100%;
}
.dv_reservas_container[data-v-b91ef9a8] {
    width: 100%;
    height: 60vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    padding-top: 10vh;
}
.dv_reservas_tit_cont[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 9vh;
    margin-top: 10vh;
}
.dv_reservas_divisor[data-v-b91ef9a8] {
    width: 1px;
    height: 100%;
    background-color: var(--dark-brown-font);
    margin-right: 1vw;
    margin-left: 1vw;
}
.dv_reservas_texto[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.tx_reservas1[data-v-b91ef9a8], .tx_reservas2[data-v-b91ef9a8], .tx_reservas3[data-v-b91ef9a8] {
    font-family: 'NomineeMedium';
    color: var(--light-brown);
    font-size: 3vh;
}
.tx_reservas2[data-v-b91ef9a8] {
    background-color: var(--light-brown);
    color: var(--light-yellow);
    padding: 1vh;
    cursor: pointer;
}
.tx_reservas3[data-v-b91ef9a8] {
    /*margin-top: 1vh;*/
    font-size: 3.5vh;
    line-height: 8vh;
    margin-right: 2vw;
    margin-left: 2vw;
}
.dv_box_calendar[data-v-b91ef9a8] {
    border-style: solid;
    border-width: 2px;
    border-color: var(--light-brown);
    height: 40vh;
    padding-top: 1vh;
    /*background-color: red;*/
}
.sel_reservas[data-v-b91ef9a8], .sel_reservas2[data-v-b91ef9a8] {
    border:0px;
    outline:0px;
    border: none;
    scroll-behavior: smooth;
    background-color: white;
    -webkit-appearance:none;
    font-family: 'NomineeMedium';
    font-size: 2vh;
    color: white;
    background-color: var(--dark-gray);
    border-color: var(--light-brown);
    border-style: solid;
    border-width: 2px;
    border-radius: 0;
    width: 3vw;
    padding-top: 1vh;
    padding-left: 1vh;
    padding-bottom: 0.5vh;
}
.sel_reservas2[data-v-b91ef9a8] {
    width: 10vw;
}
.tb_reserva[data-v-b91ef9a8], .tb_reserva_s[data-v-b91ef9a8], .tb_reserva_i[data-v-b91ef9a8] {
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    color: var(--light-yellow);
}

/* Semanas */
.tb_reserva_sem[data-v-b91ef9a8] {
    color: var(--light-brown);
    font-size: 1.8vh;
}

/* Selecionado */
.tb_reserva_s[data-v-b91ef9a8] {
    border-color: var(--light-brown);
}

/* Intervalo */
.tb_reserva_i[data-v-b91ef9a8] {
    background-color: black;
    /*border-color: var(--light-brown);*/
}
body[data-v-b91ef9a8] {
    padding: 10px;
}

/****************** SLIDER BANNER ******************/
.img_banner[data-v-b91ef9a8] {
    margin: 0;
    padding: 0;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_arrow[data-v-b91ef9a8] {
    /*background-color: red;*/
    width: 3vw;
    height: 3vw;
    padding: 3vw;
    padding-left: 1vw;
}
.im_arrow[data-v-b91ef9a8]:hover {
    -webkit-filter: brightness(50);
            filter: brightness(50);
}
.dv_arrow_container[data-v-b91ef9a8] {
    width: 80%;
    height: 6vw;
    display: inline-block;
    position: absolute;
    left: 10vw;
    margin-top: 7.5vw;
    z-index: 30;
}
.div_arrow[data-v-b91ef9a8] {
    /*background-color: red;*/
    /*position: absolute;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 2;
    height: 100%;
}
.img_container[data-v-b91ef9a8] {
    width: 100%;
    height: 100%;
    /*display: flex;*/
    /*text-align: center;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    background-position: center;
    /*background-size: 100% 100%;*/
    background-size: cover;
}
.slider[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.slide1[data-v-b91ef9a8], .slide2[data-v-b91ef9a8] {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: 0.6s ease;
    transition: 0.6s ease;
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
}
.slide1.active[data-v-b91ef9a8], .slide2.active[data-v-b91ef9a8] {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
.slide1.active ~ .slide1[data-v-b91ef9a8] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}
.slide2.active ~ .slide2[data-v-b91ef9a8] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}
.slide1[data-v-b91ef9a8], .slide2[data-v-b91ef9a8] {
    text-align: left;
    color: white;
}

/************ BLOG **************/
.root_blog[data-v-b91ef9a8] {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: var(--light-yellow);
}
.blog1[data-v-b91ef9a8] {
    margin-top: 15vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 80%;
    /*background-color: pink;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.blog1_p1[data-v-b91ef9a8] {
    width: 59%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog1_p2[data-v-b91ef9a8] {
    width: 37%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: blue;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.dv_blog1_inspira[data-v-b91ef9a8] {
    margin-top: 5vh;
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.tx_texto_inspira[data-v-b91ef9a8], .tx_inspira[data-v-b91ef9a8], .tx_autor[data-v-b91ef9a8] {
    color: var(--dark-brown);
}
.tx_inspira[data-v-b91ef9a8] {
    /*background-color: var(--light-yellow);*/
    font-family: 'Hanie';
    font-size: 4vw;
    position: relative;
    /*transform: translate(-50%, -50%);*/
    /*--webkit-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    bottom: -2vh;
}
.dv_blog1_inspira_title[data-v-b91ef9a8] {
    background-color: var(--light-yellow);
    padding-right: 1vw;
    margin-top: -4vw;
    margin-left: -0.1vw;
}
.tx_texto_inspira[data-v-b91ef9a8] {
    -ms-flex-item-align: center;
        align-self: center;
    font-family: 'NomineeMediumExtended';
    width: 80%;
    font-size: 1.2vw;
    text-align: left;
    margin-top: 2vh;
}
.tx_autor[data-v-b91ef9a8] {
    -ms-flex-item-align: center;
        align-self: center;
    font-family: 'NomineeMediumExtended';
    width: 80%;
    margin-top: 2vh;
    font-size: 1vw;
    text-align: left;
    margin-bottom: 2vh;
}
.dv_blog1_black[data-v-b91ef9a8] {
    margin-top: 3vh;
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
}
.tx_blog1_destaque[data-v-b91ef9a8] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    background-color: var(--dark-gray);
    font-size: 1.5vw;
    padding: 2vh;
}
.tx_blog1_subtitle[data-v-b91ef9a8] {
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    text-align: left;
}
.tx_blog1_subtitle_dest[data-v-b91ef9a8] {
    font-family: 'NomineeMediumExtended';
    color: var(--dark-gray-font);
    background-color: white;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.3vw;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    height: 8vh;
}
.dv_semana_container[data-v-b91ef9a8] {
    margin-top: 1vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    color: var(--light-yellow);
    width: 90%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-item-align: center;
        align-self: center;
    margin-bottom: 1.3vh;
}
.tx_semana[data-v-b91ef9a8], .tx_semana_mar[data-v-b91ef9a8] {
    margin-top: 0.2vh;
    font-size: 0.8vw;
}
.tx_semana_mar[data-v-b91ef9a8] {
    color: var(--light-yellow);
    -ms-flex-item-align: center;
        align-self: center;
    font-size: 0.8vw;
    margin-left: 1vw;
}
.tx_semana_t[data-v-b91ef9a8] {
    font-family: 'NomineeMedium';
    font-size: 1.3vw;
}
.im_semana_t[data-v-b91ef9a8] {
    height: 1.4vw;
    -o-object-fit: contain;
       object-fit: contain;
    /*background-color: red;*/
}
.blog2[data-v-b91ef9a8] {
    margin-top: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 80%;
    /*background-color: pink;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.tx_blog2_title[data-v-b91ef9a8] {
    font-family: 'NomineeMediumExtended';
    width: 100%;
    height: 8vh;
    background-color: var(--light-gray-font);
    color: white;
    font-size: 2.7vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    white-space: pre;
    margin-bottom: 2vh;
}
.tx_blog2_subtitle[data-v-b91ef9a8] {
    background: var(--dark-brown);
    color: var(--light-yellow);
    /*font-family: 'NomineeMedium';*/
    /*display:inline;*/
    font-family: 'NomineeMedium';
    font-size: 1.2vw;
    line-height: 1.8vw;
    /*padding-bottom: -12px;*/
    padding-top: 0.2vw;
    padding-bottom: 0.1vw;
    padding-right: 0.2vw;
    padding-left: 0.2vw;
    max-height: 0.5vw;
}
.dv_blog2_subtitle[data-v-b91ef9a8] {
    /*display: inline-block;*/
    color: white;
    width: 98%; /* Width to define where line break should appear */
    text-align: left;
    /*background-color: blue;*/
    /*margin-left: 2vw;*/
    margin-top: 1vh;
    margin-bottom: 1vh
}
.tx_blog2_subtitle2[data-v-b91ef9a8] {
    color: var(--light-yellow);
    font-family: 'NomineeExtraLight';
    /*display:inline;*/
    /*font-size: 0.7vw;*/
    font-size: 0.9vw;
    line-height: 1.2vw;
    /*margin-left: 2vw;*/
    text-align: left;
    display: none;
}
.dv_blog2_evento_container1[data-v-b91ef9a8] {
    width: 100%;
    overflow-x: scroll;
}
.dv_blog2_evento_container2[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    overflow: hidden;
}
.dv_blog2_evento_p1[data-v-b91ef9a8] {
    height: 80%;
    width: 100%;
    /*background-color: yellow;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.dv_blog2_evento_p2[data-v-b91ef9a8] {
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;

    -webkit-box-direction: normal;

        -ms-flex-direction: column;

            flex-direction: column;
    height: 20%;
    /*background-color: red;*/
    width: 80%;
    overflow-y: hidden;
}
.dv_blog2_evento_im_mask[data-v-b91ef9a8] {
    width: 100%;
    height: 3%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: -2%
}
.dv_blog2_evento[data-v-b91ef9a8] {
    width: 18vw;
    height: 20vw;
    /*max-height: 20vw;*/
    /*background-color: blue;*/
    background-color: var(--dark-gray);
    margin-right: 2vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: hidden;
    padding-bottom: 2vh;
    /*background-color: red;*/
}
.dv_blog2_evento:hover > .dv_blog2_evento_p1[data-v-b91ef9a8] {
    /*display: none;*/
    height: 0;
    width: 100%;/* Adicionado 27/10/2021 */
    -webkit-transition: height .3s linear;
    transition: height .3s linear;
}
.dv_blog2_evento:hover > .dv_blog2_evento_p2[data-v-b91ef9a8] {
    height: 100%;
    -webkit-transition: height .3s linear;
    transition: height .3s linear;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_blog2_evento:hover .dv_blog2_evento_im_mask[data-v-b91ef9a8] {
    display: none;
}
.dv_blog2_evento:hover .tx_blog2_subtitle[data-v-b91ef9a8] {
    display: none;
}
.dv_blog2_evento:hover .tx_blog2_subtitle2[data-v-b91ef9a8] {
    display: unset;
}
.blog3[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    /*background-color: red;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 80%;
    height: 60vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.blog3_p1[data-v-b91ef9a8] {
    width: 65%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog3_p2[data-v-b91ef9a8] {
    width: 32%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog3_p1_noticia[data-v-b91ef9a8] {
    width: 92%;
    min-height: var(--height-news);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 2vh;
    cursor: pointer;
}
.blog3_p1_container[data-v-b91ef9a8] {
    width: 100%;
    height: 100%;
    background-color: var(--dark-brown);
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 3vh;
}
.blog3_p1_im[data-v-b91ef9a8] {
    width: 27%;
    min-width: 27%;
    /*height: 100%;*/
    height: var(--height-news);
    position: relative;
    -o-object-fit: cover;
       object-fit: cover;
}
.blog3_p1_subcont[data-v-b91ef9a8] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding-right: 2%;
    height: var(--height-news);
    /*position: relative;*/
    border-color: var(--light-gray-font);
    border-style: solid;
    border-width: 1px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-left: 2vw;
}
.blog3_tx1[data-v-b91ef9a8] {
    color: white;
    font-family: 'NomineeMedium';
    font-size: 1vw;
    text-align: left;
}
.blog3_tx2[data-v-b91ef9a8] {
    color: var(--light-yellow);
    font-family: 'NomineeExtraLight';
    font-size: 0.8vw;
    text-align: left;
    max-lines: 3;
    overflow-y: hidden;
}
.blog4[data-v-b91ef9a8] {
    width: 80%;
    height: 60vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding-right: 2vw;
    padding-left: 2vw;
    /*padding-bottom: 2vw;*/
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.blog4_tx_title[data-v-b91ef9a8] {
    font-family: 'Hanie';
    font-size: 4vw;
    color: var(--dark-brown);
    line-height: 4vw;
    background-color: var(--light-yellow);
    /*background-color: blue;*/
    margin-top: -1vw;
    /*margin-bottom: -2vw;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 1vw;
}
.blog4_dv_container[data-v-b91ef9a8] {
    /*margin-bottom: -4vh;*/
    /*background-color: purple;*/
    width: 100%;
    height: 52vh;
    overflow-x: scroll;
    overflow-y: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.blog4_dv_el[data-v-b91ef9a8] {
    width: 25vw;
    min-width: 25vw;
    margin-right: 2vw;
}
.videoC[data-v-b91ef9a8] {
    width: 100%;
    max-height: 55vh;
    /*object-fit: cover;*/
    /*object-fit: scale-down;*/
    -o-object-fit: fill;
       object-fit: fill;
    /*background-color: var(--dark-gray);*/
    background-color: black;
    padding: 0;
    /*height: 440px;*/
    /*display: none;*/
}
.im_selo[data-v-b91ef9a8] {
    height: 20vh;
    width: 15vh;
    -o-object-fit: cover;
       object-fit: cover;
}
.im_pop[data-v-b91ef9a8] {
    width: unset;
}

/************ BLOG **************/

/****************** SLIDER BANNER ******************/

/* celular com tella normal/grande */
@media (max-width:1049px)  {
.tx_title_galeria[data-v-b91ef9a8] {
        margin-top: 9vh;
        margin-bottom: -7vh;
        font-size: 6vw;
}
.im_pop[data-v-b91ef9a8] {
        width: 110%;
}
.videoC[data-v-b91ef9a8] {
        background-color: unset;
}

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
.top-bar-item[data-v-b91ef9a8] {
        display: none;
}
.top-bar[data-v-b91ef9a8] {
        width: 100vw;
        height: 7vh;
}
.root[data-v-b91ef9a8] {
        width: 100vw;
        /*max-width: 100vw !important;*/
        /*background-color: red;*/
}
body[data-v-b91ef9a8] {
        width: 100vw;
        max-width: 100vw !important;
        /*background-color: purple;*/
}
.dv_historia[data-v-b91ef9a8] {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        height: auto;
        /*overflow-x: scroll;*/
}
.dv_historia_sub1[data-v-b91ef9a8] {
        width: 80%;
        padding: 0;
}
.dv_historia_sub2[data-v-b91ef9a8] {
        width: 80%;
        height: auto;
        padding-right: 0;
        margin-bottom: 5vh;
}
.tx_title_historia[data-v-b91ef9a8] {
        font-size: 7vh;
}
.dv_colum_comodidades[data-v-b91ef9a8] {
        /*background-color: red;*/
        width: 80%;
}
.dv_colum_comodidades_container[data-v-b91ef9a8] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-x: scroll;*/
        height: auto;
}
.dv_row_comodidades_container[data-v-b91ef9a8] {
        height: 15vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        /*background-color: red;*/
        width: 93%;
}
.im_ic_comodidades[data-v-b91ef9a8] {
        margin-right: 1vh;
        width: 4vh;
        height: 4vh;
}
.tx_title_comodidades[data-v-b91ef9a8] {
        font-size: 8vh;
        margin-bottom: 0;
        /*background-color: yellow;*/
        /*height: 15vh;*/
        margin-bottom: -5vh;
}
.dv_popup_mail[data-v-b91ef9a8], .dv_popup_mail2[data-v-b91ef9a8], .dv_popup_mail3[data-v-b91ef9a8], .dv_popup_checkin[data-v-b91ef9a8], .dv_popup_image[data-v-b91ef9a8] {
        width: 90vw;
        max-width: 90vw !important;
        /*background-color: red;*/
        padding: 3vw;
}
.dv_popup_checkin[data-v-b91ef9a8] {
        width: 95vw;
        max-width: 95vw !important;
        height: 55.23vw;
}
.dv_popup_image[data-v-b91ef9a8] {
        width: 80vw;
        max-width: 80vw !important;
        padding: 0;
}
.dv_localizacao_container[data-v-b91ef9a8] {
        width: 100%;
        /*height: 100%;*/
        height: auto;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        /*overflow-x: scroll;*/
}
.dv_localizacao_sub1[data-v-b91ef9a8] {
        width: 100%;
        height: 80vh;
}
.dv_localizacao_sub2[data-v-b91ef9a8] {
        width: 100%;
        height: 50vh;
}
.tx_title_gastro[data-v-b91ef9a8] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        /*margin-bottom: 5vh;*/
        /*background-color: orange;*/
}
.tx_descricao_gastro[data-v-b91ef9a8] {
        margin-top: -2vh;
        text-align: right;
        padding-right: 2%;
        /*background-color: red;*/
}
.dv_colum_gastronomia_container[data-v-b91ef9a8] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-x: scroll;*/
        /*height: 85vh;*/
        height: auto;
}
.dv_colum_gastronomia[data-v-b91ef9a8], .dv_colum_gastronomia3[data-v-b91ef9a8] {
        width: 80%;
        /*height: 60%;*/
        height: 55vh;
}
.dv_colum_gastronomia3[data-v-b91ef9a8] {
        margin-bottom: 5vh;
}
.dv_row_gastronomia_container[data-v-b91ef9a8] {
        margin-top: 5vh;
        height: 15vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        width: 95%;
}
.dv_gastronomia[data-v-b91ef9a8] {
        height: 45vh;
}
.dv_gastronomia_overlay[data-v-b91ef9a8] {
        height: 45vh;
}
.dv_footbar_container[data-v-b91ef9a8] {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.dv_footbar_colum0[data-v-b91ef9a8] {
        width: 40%;
        margin-left: 2vw;
}
.dv_footbar_colum[data-v-b91ef9a8] {
        width: 40%;
}
.dv_footbar_colum2[data-v-b91ef9a8] {
        display: none;
}
.dv_acomodacoes_tit_cont[data-v-b91ef9a8] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.tx_acomodacaoes_subtitle[data-v-b91ef9a8], .tx_acomodacaoes_subtitle_s[data-v-b91ef9a8] {
        width: 30%;
        font-size: 1.5vh;
        margin-top: 1vh;
        margin-bottom: 1vh;
}
.tx_title_acomoda[data-v-b91ef9a8] {
        /*width: 100%;*/
        font-size: 7vh;
        /*background-color: red;*/
}
.dv_acomodacoes_container[data-v-b91ef9a8] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-y: scroll;*/
        height: auto;
}
.dv_row_acomodacoes_container[data-v-b91ef9a8] {
        width: 95%;
}
.dv_row_acomodacoes_container_ft[data-v-b91ef9a8], .dv_row_comodidades_container_ft[data-v-b91ef9a8], .dv_row_gastro_container_ft[data-v-b91ef9a8] {
        width: 100%;
}
.dv_acomodacoes_sub1[data-v-b91ef9a8] {
        width: 100%;
        height: 50vh;
}
.dv_descricao_acomoda[data-v-b91ef9a8] {
        width: 100%;
        height: 60vh;
}
.tx_descricao_acomoda[data-v-b91ef9a8] {
        height: 60vh !important;
}
.dv_reservas_container[data-v-b91ef9a8] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*height: 43vh;*/
        /*height: 80vh;*/
        /*overflow-y: scroll;*/
        height: auto;
        padding-top: 2vh;
}
.dv_colum_reservas[data-v-b91ef9a8] {
        width: 80%;
        height: 45vh;
}
.dv_box_calendar[data-v-b91ef9a8] {
        height: 35vh;
}
.sel_reservas[data-v-b91ef9a8] {
        width: 10vw;
}
.sel_reservas2[data-v-b91ef9a8] {
        width: 37vw;
}
.dv_reservas_tit_cont[data-v-b91ef9a8] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-top: 2vh;
        height: 15vh;
}
.dv_reservas_divisor[data-v-b91ef9a8], .dv_reservas_texto[data-v-b91ef9a8] {
        display: none;
}
.tx_title_reservas[data-v-b91ef9a8] {
        width: 100%;
        font-size: 10vh;
}
.tx_title_newsletter[data-v-b91ef9a8] {
        font-size: 7vh;
}
.dv_gastro_divisor[data-v-b91ef9a8] {
        display: none;
}
.historia[data-v-b91ef9a8], .comodidades[data-v-b91ef9a8], .localizacao[data-v-b91ef9a8], .gastronomia[data-v-b91ef9a8], .acomodacoes[data-v-b91ef9a8], .reservas[data-v-b91ef9a8], .faleconosco[data-v-b91ef9a8], .newsletter[data-v-b91ef9a8] {
        height: auto;
}
.dv_home_selos[data-v-b91ef9a8] {
        bottom: 2vh;
        right: 5vw;
}
.im_selo[data-v-b91ef9a8] {
        height: 10vh;
        width: 7.5vh;
        -o-object-fit: cover;
           object-fit: cover;
}
.dv_descricao_acomoda[data-v-b91ef9a8]::-webkit-scrollbar
    {
        width: 3vw;
}
.popup_overlay2[data-v-b91ef9a8] {
        display: none;
}

    /*  Blog  */
.blog1[data-v-b91ef9a8] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-top: 10vh;
}
.blog1_gal[data-v-b91ef9a8] {
        margin-top: 5vh;
}
.blog1_p1[data-v-b91ef9a8] {
        width: 100%;
}
.blog1_p2[data-v-b91ef9a8] {
        width: 100%;
}
.tx_blog2_title[data-v-b91ef9a8] {
        font-size: 2vw;
}
.tx_blog1_subtitle_dest[data-v-b91ef9a8] {
        font-size: 2.2vw;
}
.tx_semana_t[data-v-b91ef9a8] {
        font-size: 3vw;
}
.tx_semana[data-v-b91ef9a8] {
        font-size: 2vw;
}
.im_semana_t[data-v-b91ef9a8] {
        height: 3.5vw;
}
.tx_semana_mar[data-v-b91ef9a8] {
        font-size: 2vw;
}
.tx_inspira[data-v-b91ef9a8] {
        font-size: 10vw;
        bottom: -1vh;
}
.dv_blog1_inspira_title[data-v-b91ef9a8] {
        margin-top: -8vw;
        margin-left: -0.7vw;
}
.tx_texto_inspira[data-v-b91ef9a8] {
        font-size: 2.5vw;
}
.tx_autor[data-v-b91ef9a8] {
        font-size: 2vw;
}
.blog3[data-v-b91ef9a8] {
        /*height: 100vh;*/
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: auto;
}
.blog3_p1[data-v-b91ef9a8] {
        width: 100%;
        height: auto;
        /*height: 50vh;*/
}
.blog3_p2[data-v-b91ef9a8] {
        margin-top: 2vh;
        width: 100%;
        height: 48vh;
}
.blog3_tx1[data-v-b91ef9a8] {
        font-size: 2vw;
}
.blog3_tx2[data-v-b91ef9a8] {
        font-size: 1.8vw;
}
.tx_blog1_destaque[data-v-b91ef9a8] {
        font-size: 2.5vw;
}
.blog3_p1_noticia[data-v-b91ef9a8] {
        min-height: var(--height-news_m);
}
.blog3_p1_im[data-v-b91ef9a8] {
        height: var(--height-news_m);
}
.blog3_p1_subcont[data-v-b91ef9a8] {
        height: var(--height-news_m);
}
.dv_blog2_evento[data-v-b91ef9a8] {
        width: 40vw;
        height: 50vw;
}
.dv_blog2_subtitle[data-v-b91ef9a8] {
        width: 100%;
        margin-top: 0;
}
.tx_blog2_subtitle[data-v-b91ef9a8] {
        font-size: 2.7vw;
        line-height: 2.7vw;
        padding-top: 0.4vh;
        padding-bottom: 0.1vh;
}
.tx_blog2_subtitle2[data-v-b91ef9a8] {
        font-size: 2.3vw;
        line-height: 2.6vw;
}
.im_arrow[data-v-b91ef9a8] {
        width: 8vw;
        height: 8vw;
        padding: 8vw;
        padding-left: 1vw;
}
.dv_arrow_container[data-v-b91ef9a8] {
        margin-top: 23vw;
}
.dv_blog2_evento_p2[data-v-b91ef9a8] {
        height: 6vh;
        vertical-align: top;
}
.tx_popup4[data-v-b91ef9a8] {
        font-size: 2vh;
}
.blog4_dv_container[data-v-b91ef9a8] {
        /*background-color: red;*/
        height: 57vh;
}
.blog4_dv_el[data-v-b91ef9a8] {
        /*width: 40vw;*/
        min-width: 60vw;
}
}

/* celular com tel pequena */
@media (max-width:370px)  {
.dv_row_acomodacoes_container[data-v-b91ef9a8] {
        width: 75%;
}
.dv_row_comodidades_container[data-v-b91ef9a8] {
        width: 75%;
}
.dv_row_gastronomia_container[data-v-b91ef9a8] {
        width: 88%;
}
}
@media (min-width:1050px) {
    /* big landscape tablets, laptops, and desktops */
.top-bar-item[data-v-b91ef9a8] {
        display: unset;
}
.dv_historia[data-v-b91ef9a8] {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
}
.section[data-v-b91ef9a8] {
        display: none;
}
}
@media (min-width:1900px) {
    /* big screens desktops */
.videoC[data-v-b91ef9a8] {
        height: 45vh;
}
}

















































































































































































































































































































































































































































/*
    DEMO STYLE
*/
p[data-v-845db73a] {
    /*font-family: 'Poppins', sans-serif;*/
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}
a[data-v-845db73a], a[data-v-845db73a]:hover, a[data-v-845db73a]:focus {
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.navbar[data-v-845db73a] {
    padding: 2.3vw 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}
.navbar-btn[data-v-845db73a] {
    -webkit-box-shadow: none;
            box-shadow: none;
    outline: none !important;
    border: none;
}
.line[data-v-845db73a] {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebar[data-v-845db73a] {
    width: 78%;
    position: fixed;
    top: 0;
    left: -78%;
    height: 100vh;
    z-index: 101;
    /*background: #0171bbBB;*/
    /*background-color: red;*/
    background-color: var(--dark-brown-trans);
    /*color: #fff;*/
    color: var(--light-yellow);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    overflow-y: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;

    -webkit-box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
#sidebar[data-v-845db73a]::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
    display: none;
}
#sidebar.active[data-v-845db73a] {
    left: 0;
}
#dismiss[data-v-845db73a] {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #549CCB;
    position: absolute;
    top: 20px;
    left: 2.3vw;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.overlay[data-v-845db73a] {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(10, 10, 10, 0.6);
    z-index: 98;
    display: none;
}
#sidebar .sidebar-header[data-v-845db73a] {
    padding: 20px;
    background-color: var(--dark-brown);
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
#sidebar ul.components[data-v-845db73a] {
    padding: 20px 0;
    /*border-bottom: 1px solid #47748b;*/
}
#sidebar ul p[data-v-845db73a] {
    color: #fff;
    padding: 10px;
}
#sidebar ul li a[data-v-845db73a] {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}
#sidebar ul li a[data-v-845db73a]:hover {
    /*color: #0171bb;*/
    color: var(--dark-brown);
    background: #fff;
}
#sidebar ul li.active > a[data-v-845db73a], a[aria-expanded="true"][data-v-845db73a] {
}
a[data-toggle="collapse"][data-v-845db73a] {
    position: relative;
}
a[aria-expanded="false"][data-v-845db73a]::before, a[aria-expanded="true"][data-v-845db73a]::before {
    content: '\E259';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 1.0em;
}
a[aria-expanded="true"][data-v-845db73a]::before {
    content: '\E260';
}
ul ul a[data-v-845db73a] {
    font-size: 1.05em !important;
    padding-left: 2.3vw !important;
}
ul.CTAs[data-v-845db73a] {
    padding: 20px;
}
ul.CTAs a[data-v-845db73a] {
    text-align: center;
    font-size: 1.0em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content[data-v-845db73a] {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}
@media (min-width:320px)  {
    /* smartphones, iPhone, portrait 480x320 phones */
#sidebar[data-v-845db73a] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:481px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
#sidebar[data-v-845db73a] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:521px)  {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
#sidebar[data-v-845db73a] {
        width: 78%;
        left: -78%;
}
}
@media (min-width:641px)  {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
#sidebar[data-v-845db73a] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:861px)  {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
#sidebar[data-v-845db73a] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:1125px) {
    /* big landscape tablets, laptops, and desktops */
#sidebar[data-v-845db73a] {
        width: 30%;
        left: -30%;
}
}
@media (min-width:1281px) {
    /* hi-res laptops and desktops */
#sidebar[data-v-845db73a] {
        width: 30%;
        left: -30%;
}
}
/*https://codepen.io/jorenerene/pen/vxKbJb*/
/*https://codepen.io/bennettfeely/pen/twbyA*/
.navicon-button[data-v-845db73a] {
    display: inline-block;
    padding: 1.0625rem 0.5rem;
    -webkit-transition: 0.15s;
    transition: 0.15s;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    opacity: 0.8;
    /*background-color: red;*/
}
.navicon-button .navicon[data-v-845db73a]:before, .navicon-button .navicon[data-v-845db73a]:after {
    -webkit-transition: 0.15s;
    transition: 0.15s;
}
/*.navicon-button:not(:focus):hover > .navicon {*/
/*transition: 0.3s;*/
/*width: 2.5rem;*/
/*!*background-color: red;*!*/
/*}*/
/*.navicon-button:hover {*/
/*transition: 0.3s;*/
/*opacity: 1;*/
/*}*/
/*.navicon-button:hover .navicon:before, .navicon-button:hover .navicon:after {*/
/*transition: 0.15s;*/
/*}*/
/*.navicon-button:hover .navicon:before {*/
/*top: 0.925rem;*/
/*}*/
/*.navicon-button:hover .navicon:after {*/
/*top: -0.925rem;*/
/*}*/
.navicon[data-v-845db73a] {
    position: relative;
    width: var(--menu-icon-width_small);
    /*height: 0.3125rem;*/
    height: var(--menu-icon-height);
    /*max-height: var(--height-menu-icon);*/
    background: var(--menu-icon-gray);
    /*background-color: green;*/
    -webkit-transition: 0.5s;
    transition: 0.5s;
    /*border-radius: 2.5rem;*/
    border-radius: 1rem;
}
.navicon[data-v-845db73a]:before, .navicon[data-v-845db73a]:after {
    display: block;
    content: "";
    /*height: 0.3125rem;*/
    height: var(--menu-icon-height);
    /*max-height: var(--height-menu-icon);*/
    /*min-height: var(--height-menu-icon);*/
    /*height: 0.2rem;*/
    width: var(--menu-icon-width);
    background: var(--menu-icon-gray);
    position: absolute;
    z-index: 998;
    -webkit-transition: 0.3s 0.15s;
    transition: 0.3s 0.15s;
    border-radius: 2rem;
    /*background-color: blue;*/
}
.navicon[data-v-845db73a]:before {
    /*top: 0.825rem;*/
    top: var(--menu-icon-spacing);
}
.navicon[data-v-845db73a]:after {
    /*top: -0.825rem;*/
    top: calc(-1 * var(--menu-icon-spacing));
}
.open:not(.steps) .navicon[data-v-845db73a]:before, .open:not(.steps) .navicon[data-v-845db73a]:after {
    top: 0 !important;
}
.open .navicon[data-v-845db73a]:before, .open .navicon[data-v-845db73a], .open .navicon[data-v-845db73a]:after {
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
/* × */
.open.x .navicon[data-v-845db73a] {
    background: transparent;
}
.open.x .navicon[data-v-845db73a]:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.open.x .navicon[data-v-845db73a]:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.open.x .navicon[data-v-845db73a] {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.section[data-v-845db73a] {
    position: fixed;
    display: inline-block;
    top: 0;
    left: 0;
    /*margin: -42px auto;*/
    margin-top: var(--menu-icon-top);
    margin-left: 1vw;
    /*background: red;*/
    /*overflow: hidden;*/
    z-index: 100000;
    /* Smoother animations */
}
.section *[data-v-845db73a], .section *[data-v-845db73a]:before, .section *[data-v-845db73a]:after {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
@media (min-width:320px)  {
}
@media (min-width:641px)  {
    /*Botei esso aqui, pois quando tava clicando no botao do menu no celular, quando voltava ele fica expandido (a linha do meio.)*/
.navicon-button:hover > .navicon[data-v-845db73a] {
        -webkit-transition: 0.3s;
        transition: 0.3s;
        width: var(--menu-icon-width);

        /*background-color: pink;*/
}
}

.top-bar[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    margin-top: 5vh;
    height: 5vh;
    width: 100%;
    background-color: #000000AA;
    z-index: 10;
}
.top-bar-item[data-v-845db73a] {
    color: white;
    font-family: 'NomineeExtraLight';
    cursor: pointer;
}
.top-logo[data-v-845db73a], .top-logo_b[data-v-845db73a] {
    height: 8vh;
    /*height: 4vh;*/
    /*height: 160%;*/
    /*height: 90%;*/
    margin-top: 1.5vh;
    /*margin-top: 0vh;*/
    /*margin-top: 0.85%;*/
    cursor: pointer;
}
.top-logo_b[data-v-845db73a] {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(81%, var(--light-yellow)), color-stop(19%, transparent));
    background: linear-gradient(180deg, var(--light-yellow) 81%, transparent 19%);
}
.top-bar-item[data-v-845db73a]:hover {
    border-bottom: 1px solid white;
}
.tx_title[data-v-845db73a], .tx_title_gastro[data-v-845db73a], .tx_title_historia[data-v-845db73a], .tx_title_localizacao[data-v-845db73a], .tx_title_comodidades[data-v-845db73a], .tx_title_acomoda[data-v-845db73a], .tx_title_reservas[data-v-845db73a], .tx_title_newsletter[data-v-845db73a] {
    color: black;
    font-size: 11vh;
    font-family: 'Hanie';
}
.tx_title_historia[data-v-845db73a] {
    font-size: 9vh;
    text-align: left;
}
.tx_title_localizacao[data-v-845db73a] {
    color: var(--light-yellow);
    margin-bottom: 2vh;
    font-size: 7vh;
}
.tx_title_comodidades[data-v-845db73a] {
    color: var(--light-brown);
    margin-top: -2vh;
    margin-bottom: 0vh;
    height: 20vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    /*background-color: blueviolet;*/
}
.tx_title_acomoda[data-v-845db73a] {
    color: var(--dark-brown);
    margin-bottom: -5vh;
    font-size: 8vh;
    /*width: 100%;*/
    /*background-color: yellow;*/
}
.tx_title_reservas[data-v-845db73a] {
    color: var(--dark-brown-font);
    margin-bottom: -2vh;
    font-size: 9vh;
}
.tx_descricao[data-v-845db73a], .tx_descricao_gastro[data-v-845db73a], .tx_subtitle_acomoda[data-v-845db73a], .tx_subtitle_comodidade[data-v-845db73a] {
    color: var(--light-gray-font);
    font-size: 2.2vh;
    line-height: 4.5vh;
    font-family: 'NomineeMediumExtended';
    text-align: left;
}
.tx_title_galeria[data-v-845db73a] {
    font-size: 5vh;
    margin-top: 15vh;
    margin-bottom: -10vh;
    color: var(--light-brown);
}
.tx_descricao_gastro[data-v-845db73a], .tx_subtitle_acomoda[data-v-845db73a], .tx_subtitle_comodidade[data-v-845db73a] {
    font-size: 1.6vh;
    line-height: 3.5vh;
}
.tx_comodidades_title[data-v-845db73a] {
    font-family: 'NomineeExtraLight';
    color: white;
    font-size: 3vh;
}
.tx_subtitle_comodidade[data-v-845db73a] {
    text-align: right;
}
.tx_comodidades_desc[data-v-845db73a] {
    font-family: 'NomineeMediumExtended';
    color: white;
    font-size: 2vh;
    font-size: 1.8vh;
    line-height: 3vh;
}
.tx_endereco[data-v-845db73a] {
    font-family: 'NomineeMediumExtended';
    color: var(--dark-gray-font);
    font-size: 2.5vh;
    line-height: 4vh;
}
.tx_gastronomia_title[data-v-845db73a], .tx_gastronomia_title_s[data-v-845db73a] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    font-size: 2.5vh;
    line-height: 7vh;
    cursor: pointer;
    white-space: pre;
}
.tx_gastronomia_title_s[data-v-845db73a] {
    border-bottom: 1px solid var(--light-brown);
}

/*.tx_gastronomia_title:hover {*/

/*border-bottom: 1px solid var(--light-brown);*/

/*}*/
.tx_social_gastronomia[data-v-845db73a] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    font-size: 2.5vh;
    line-height: 4vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
}
.tx_social_gastronomia[data-v-845db73a]:hover {
    border-bottom: 2px solid var(--light-brown);
}
.tx_descricao_acomoda[data-v-845db73a], .tx_descricao_acomoda_topics[data-v-845db73a] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    width: 70%;
    /*white-space: pre;*/
    /*background-color: red;*/
    text-align: left;
    font-size: 1.7vh;
    line-height: 4vh;
    /*display: flex;*/
}
.tx_descricao_acomoda[data-v-845db73a] {
    white-space: unset;
    /*margin-bottom: 2vh;*/
    /*margin-top: 5vh;*/
    font-size: 2vh;
    line-height: 5vh;
    /*background-color: red;*/
    /*min-height: 65vh;*/
    text-align: left;
    height: 70vh !important;
    min-height: 70vh !important;
    /*text-align: justify;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /*align-content: center;*/
}
.tx_acomodacaoes_subtitle[data-v-845db73a], .tx_acomodacaoes_subtitle_s[data-v-845db73a] {
    font-family: 'NomineeMediumExtended';
    color: black;
    cursor: pointer;
    margin-bottom: -4vh;
}
.tx_acomodacaoes_subtitle[data-v-845db73a]:hover {
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    padding-top: 0.2vw;
    padding-bottom: 0.05vw;
}
.tx_acomodacaoes_subtitle_s[data-v-845db73a] {
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    padding-top: 0.2vw;
    padding-bottom: 0.05vw;
}
.im_acomodacoes[data-v-845db73a] {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.dv_descricao_acomoda[data-v-845db73a] {
    width: 30%;
    height: 70vh;
    background-color: var(--dark-brown);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: scroll;
}

/*background do scroll bar*/
.dv_descricao_acomoda[data-v-845db73a]::-webkit-scrollbar-track
{
    /*-webkit-box-shadow: inset 0 0 6px rgba(1,0,0,0.3);*/
    -webkit-box-shadow: none;
    border-radius: 10px;
    /*background-color: #FF0000;*/
    background-color: transparent;
}

/*background do scroll bar*/
.dv_descricao_acomoda[data-v-845db73a]::-webkit-scrollbar
{
    width: 1vw;
    /*background-color: #FF00F5;*/
    background-color: transparent;
}

/* FORE GROUND do scroll bar*/
.dv_descricao_acomoda[data-v-845db73a]::-webkit-scrollbar-thumb
{
    border-radius: 8px;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,1);*/
    -webkit-box-shadow: none;
    background-color: var(--light-yellow);
    /*background-color: red;*/
}
.root[data-v-845db73a] {
    width: 100%;
    height: 100vh;
    display: block;
    /*background-color: red;*/
}
.im_slider[data-v-845db73a] {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
.im_historia[data-v-845db73a] {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_ic_comodidades[data-v-845db73a] {
    margin-right: 0.5vw;
    width: 2vw;
    height: 2vw;
}
.im_gastronomia[data-v-845db73a] {
    width: 100%;
    height: 63vh;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: 1;
}
.dv_im_container[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: yellow;*/
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.im_galery[data-v-845db73a] {
    width: 23vw;
    height: 18vw;
    -o-object-fit: cover;
       object-fit: cover;
    /*background-color: red;*/
    padding: 1vw;
}
.im_social[data-v-845db73a] {
    /*width: 2.5vh;*/
    height: 2.8vh;
    margin: 0.3vh;
    -o-object-fit: contain;
       object-fit: contain;
}
.dv_gastronomia_overlay[data-v-845db73a] {
    width: 100%;
    height: 63vh;
    /*display: flex;*/
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #000000CC;
    z-index: 10;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_gastronomia[data-v-845db73a] {
    width: 100%;
    height: 63vh;
    background-image: url('/static/imgs/im_rest01.jpg');
    background-size: cover;
    background-position: center;
    /*cursor: pointer;*/
}

/*.dv_gastronomia:hover > .dv_gastronomia_overlay {*/

/*display: flex;*/

/*}*/
.dv_colum_gastronomia[data-v-845db73a], .dv_colum_gastronomia3[data-v-845db73a] {
    width: 19.5%;
    height: 100%;
    /*background-color: red;*/
    /*margin-bottom: 1vh;*/
}
.dv_colum_gastronomia:hover .tx_gastronomia_title[data-v-845db73a] {
    border-bottom: 1px solid var(--light-brown);
}
.dv_colum_gastronomia3:hover .tx_gastronomia_title[data-v-845db73a] {
    border-bottom: 1px solid var(--light-brown);
}
.dv_colum_gastronomia:hover .dv_gastronomia_overlay[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.dv_colum_gastronomia3:hover .dv_gastronomia_overlay[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.dv_colum_gastronomia_container[data-v-845db73a] {
    width: 100%;
    height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.dv_row_gastronomia_container[data-v-845db73a] {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: horizontal;
     -webkit-box-direction: normal;
         -ms-flex-direction: row;
             flex-direction: row;
     -webkit-box-align: center;
         -ms-flex-align: center;
             align-items: center;
     height: 8vh;
}
.dv_gastro_divisor[data-v-845db73a] {
    width: 1px;
    height: 100%;
    background-color: var(--light-brown);
    margin-right: 1vw;
    margin-left: 1vw;
}
.home[data-v-845db73a], .historia[data-v-845db73a], .comodidades[data-v-845db73a], .localizacao[data-v-845db73a], .gastronomia[data-v-845db73a], .acomodacoes[data-v-845db73a], .reservas[data-v-845db73a], .footbar[data-v-845db73a], .faleconosco[data-v-845db73a], .newsletter[data-v-845db73a] {
    height: 100vh;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.home[data-v-845db73a] {
    /*display: unset;*/
    height: 100%;
    /*background-color: blue;*/
}
.dv_home_selos[data-v-845db73a] {
    position: absolute;
    bottom: 6vh;
    right: 4vw;
    z-index: 0;
    /*background-color: red;*/
}
.historia[data-v-845db73a] {
    background-color: var(--light-yellow);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.dv_historia[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: 99vh;
}
.dv_historia_sub1[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 60%;
    max-height: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 5vw;
    overflow: no-display;
}
.dv_historia_sub2[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-right: 10vw;
}
.comodidades[data-v-845db73a] {
    background-color: var(--dark-gray);
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.localizacao[data-v-845db73a] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    background-position: initial;
    /*background-repeat: no-repeat;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-size: cover;
}
.dv_localizacao_container[data-v-845db73a] {
    width: 80%;
    height: 80%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.dv_localizacao_sub1[data-v-845db73a] {
    height: 100%;
    width: 40%;
    background-color: var(--light-yellow);
}
.dv_localizacao_sub2[data-v-845db73a] {
    height: 100%;
    width: 60%;
}
.gastronomia[data-v-845db73a] {
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.acomodacoes[data-v-845db73a] {
    background-color: var(--light-yellow);
}
.dv_acomodacoes_tit_cont[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 20vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.dv_acomodacoes_container[data-v-845db73a] {
    width: 100%;
    height: 70vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    lex-direction: row;
}
.dv_row_acomodacoes_container_ft[data-v-845db73a], .dv_row_comodidades_container_ft[data-v-845db73a], .dv_row_gastro_container_ft[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
}
.dv_row_acomodacoes_container_ft[data-v-845db73a] {
    /*background-color: yellow;*/
    width: 30%;
}
.dv_row_acomodacoes_container[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    /*background-color: red;*/
    /*padding-right: 10%;*/
    width: 100%;
}
.dv_acomodacoes_sub1[data-v-845db73a] {
    width: 70%;
    height: 100%;
}
.reservas[data-v-845db73a] {
    background-color: var(--dark-gray);
}
.faleconosco[data-v-845db73a], .newsletter[data-v-845db73a] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    /*background-color: pink;*/
    background-position: center;
    background-size: cover;
    padding-top: 10vh;
    padding-right: 5%;
    padding-left: 5%;
    max-width: 90vw;
    /*height: 40vh;*/
}
.newsletter[data-v-845db73a] {
    height: 90vh;
    padding-top: 20vh
}
.footbar[data-v-845db73a] {
    background-color: var(--light-gray-font);
    background-image: url("/static/imgs/bg_cinza_hd.jpg");
    background-position: center;
    background-size: cover;
    height: 20vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*background-color: red;*/
}
.dv_footbar_container[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 100%;
    height: 100%;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
}
.dv_footbar_colum0[data-v-845db73a], .dv_footbar_colum[data-v-845db73a], .dv_footbar_colum2[data-v-845db73a] {
    width: 20%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.tx_footbar[data-v-845db73a], .tx_footbar_desc[data-v-845db73a] {
    color: white;
    font-family: 'NomineeExtraLight';
    white-space: pre;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2vh;
    margin-bottom: 2vh;
    /*background-color: blue;*/
}
.tx_footbar_desc[data-v-845db73a] {
    font-family: 'NomineeMedium';
    margin-top: 0;
    margin-bottom: 0;
}
.dv_colum_comodidades[data-v-845db73a] {
    /*background-color: red;*/
    width: 25%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    /*align-items: flex-start;*/
    /*align-content: flex-start;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.dv_colum_comodidades_container[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    height: 65vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    padding-right: 4vw;
    padding-left: 4vw;
    /*background-color: red;*/
}
.dv_row_comodidades_container[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 8vh;
    margin-top: 8vh;
    /*background-color: red;*/
}
.dv_line_comodidades[data-v-845db73a] {
    /*background-color: blue;*/
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    text-align: left;
    margin-bottom: 5vh;
}
.bt_enviar[data-v-845db73a] {
    margin-top: .5vh;
    color: white;
    border-width: 2px;
    border-style: solid;
    font-size: 1.7vh;
    display: inline-block;
    padding-right: 0.5vw;
    padding-left: 0.5vw;
    cursor: pointer;
}
.popup_overlay[data-v-845db73a], .popup_overlay2[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100%;
    background-color: #000000AA;
    z-index: 10;
}
.popup_overlay2[data-v-845db73a] {
    position: relative;
    margin-top: -100vh;
}
.dv_popup_mail[data-v-845db73a], .dv_popup_mail2[data-v-845db73a], .dv_popup_mail3[data-v-845db73a] {
    background-color: var(--dark-brown-font);
    width: 30%;
    height: 30vh;
    padding: 2vh;
}
.dv_popup_mail[data-v-845db73a] {
    display: block;
    width: 620px;
    height: 370px;
}
.dv_popup_mail2[data-v-845db73a] {
    background-color: var(--light-gray-font);
}
.dv_popup_image[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    height: 50vh;
    cursor: pointer;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_popup_checkin[data-v-845db73a] {
    width: 30%;
    height: 30vh;
    background-image: url("/static/imgs/pop_checkin2.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.dv_popup_mail3[data-v-845db73a] {
    width: 25%;
    height: 25vh;
    background-color: var(--light-gray-font2);
}
.im_whats_over[data-v-845db73a] {
    width: 80px;
    height: 80px;
    width: 8vh;
    height: 8vh;
    position: fixed;
    z-index: 10;
    bottom: 14vh;
    right: 2.5vh;
    cursor: pointer;
}
.tx_popup1[data-v-845db73a], .tx_popup2[data-v-845db73a], .tx_popup2_2[data-v-845db73a] {
    font-family: 'NomineeExtraLight';
    font-size: 4vh;
    color: var(--light-yellow);
}
.tx_popup2[data-v-845db73a], .tx_popup2_2[data-v-845db73a] {
    font-family: 'NomineeMedium';
    padding-top: 0.5vh;
    padding-left: 0.3vh;
    background-color: var(--light-yellow);
    color: var(--dark-brown-font);
}
.tx_popup2_2[data-v-845db73a] {
    color: var(--light-gray-font);
}
.tx_popup3[data-v-845db73a], .tx_popup4[data-v-845db73a] {
    font-family: 'NomineeMedium';
    font-size: 2vh;
    color: var(--light-yellow);
}
.tx_popup4[data-v-845db73a] {
    font-size: 0.9vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: 'NomineeMediumExtended';
}
.ipt_popup[data-v-845db73a] {
    width: 100%;
    height: 3vh;
    font-size: 2vh;
    text-align: center;
    color: var(--dark-brown-font);
}
.dv_colum_reservas[data-v-845db73a] {
    width: 25%;
    height: 100%;
}
.dv_reservas_container[data-v-845db73a] {
    width: 100%;
    height: 60vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    padding-top: 10vh;
}
.dv_reservas_tit_cont[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 9vh;
    margin-top: 10vh;
}
.dv_reservas_divisor[data-v-845db73a] {
    width: 1px;
    height: 100%;
    background-color: var(--dark-brown-font);
    margin-right: 1vw;
    margin-left: 1vw;
}
.dv_reservas_texto[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.tx_reservas1[data-v-845db73a], .tx_reservas2[data-v-845db73a], .tx_reservas3[data-v-845db73a] {
    font-family: 'NomineeMedium';
    color: var(--light-brown);
    font-size: 3vh;
}
.tx_reservas2[data-v-845db73a] {
    background-color: var(--light-brown);
    color: var(--light-yellow);
    padding: 1vh;
    cursor: pointer;
}
.tx_reservas3[data-v-845db73a] {
    /*margin-top: 1vh;*/
    font-size: 3.5vh;
    line-height: 8vh;
    margin-right: 2vw;
    margin-left: 2vw;
}
.dv_box_calendar[data-v-845db73a] {
    border-style: solid;
    border-width: 2px;
    border-color: var(--light-brown);
    height: 40vh;
    padding-top: 1vh;
    /*background-color: red;*/
}
.sel_reservas[data-v-845db73a], .sel_reservas2[data-v-845db73a] {
    border:0px;
    outline:0px;
    border: none;
    scroll-behavior: smooth;
    background-color: white;
    -webkit-appearance:none;
    font-family: 'NomineeMedium';
    font-size: 2vh;
    color: white;
    background-color: var(--dark-gray);
    border-color: var(--light-brown);
    border-style: solid;
    border-width: 2px;
    border-radius: 0;
    width: 3vw;
    padding-top: 1vh;
    padding-left: 1vh;
    padding-bottom: 0.5vh;
}
.sel_reservas2[data-v-845db73a] {
    width: 10vw;
}
.tb_reserva[data-v-845db73a], .tb_reserva_s[data-v-845db73a], .tb_reserva_i[data-v-845db73a] {
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    color: var(--light-yellow);
}

/* Semanas */
.tb_reserva_sem[data-v-845db73a] {
    color: var(--light-brown);
    font-size: 1.8vh;
}

/* Selecionado */
.tb_reserva_s[data-v-845db73a] {
    border-color: var(--light-brown);
}

/* Intervalo */
.tb_reserva_i[data-v-845db73a] {
    background-color: black;
    /*border-color: var(--light-brown);*/
}
body[data-v-845db73a] {
    padding: 10px;
}

/****************** SLIDER BANNER ******************/
.img_banner[data-v-845db73a] {
    margin: 0;
    padding: 0;
    -o-object-fit: contain;
       object-fit: contain;
}
.im_arrow[data-v-845db73a] {
    /*background-color: red;*/
    width: 3vw;
    height: 3vw;
    padding: 3vw;
    padding-left: 1vw;
}
.im_arrow[data-v-845db73a]:hover {
    -webkit-filter: brightness(50);
            filter: brightness(50);
}
.dv_arrow_container[data-v-845db73a] {
    width: 80%;
    height: 6vw;
    display: inline-block;
    position: absolute;
    left: 10vw;
    margin-top: 7.5vw;
    z-index: 30;
}
.div_arrow[data-v-845db73a] {
    /*background-color: red;*/
    /*position: absolute;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 2;
    height: 100%;
}
.img_container[data-v-845db73a] {
    width: 100%;
    height: 100%;
    /*display: flex;*/
    /*text-align: center;*/
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    background-position: center;
    /*background-size: 100% 100%;*/
    background-size: cover;
}
.slider[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.slide1[data-v-845db73a], .slide2[data-v-845db73a] {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: 0.6s ease;
    transition: 0.6s ease;
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
}
.slide1.active[data-v-845db73a], .slide2.active[data-v-845db73a] {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
.slide1.active ~ .slide1[data-v-845db73a] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}
.slide2.active ~ .slide2[data-v-845db73a] {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0);
}
.slide1[data-v-845db73a], .slide2[data-v-845db73a] {
    text-align: left;
    color: white;
}

/************ BLOG **************/
.root_blog[data-v-845db73a] {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: var(--light-yellow);
}
.blog1[data-v-845db73a] {
    margin-top: 15vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 80%;
    /*background-color: pink;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.blog1_p1[data-v-845db73a] {
    width: 59%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog1_p2[data-v-845db73a] {
    width: 37%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: blue;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.dv_blog1_inspira[data-v-845db73a] {
    margin-top: 5vh;
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    /*background-color: red;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.tx_texto_inspira[data-v-845db73a], .tx_inspira[data-v-845db73a], .tx_autor[data-v-845db73a] {
    color: var(--dark-brown);
}
.tx_inspira[data-v-845db73a] {
    /*background-color: var(--light-yellow);*/
    font-family: 'Hanie';
    font-size: 4vw;
    position: relative;
    /*transform: translate(-50%, -50%);*/
    /*--webkit-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    bottom: -2vh;
}
.dv_blog1_inspira_title[data-v-845db73a] {
    background-color: var(--light-yellow);
    padding-right: 1vw;
    margin-top: -4vw;
    margin-left: -0.1vw;
}
.tx_texto_inspira[data-v-845db73a] {
    -ms-flex-item-align: center;
        align-self: center;
    font-family: 'NomineeMediumExtended';
    width: 80%;
    font-size: 1.2vw;
    text-align: left;
    margin-top: 2vh;
}
.tx_autor[data-v-845db73a] {
    -ms-flex-item-align: center;
        align-self: center;
    font-family: 'NomineeMediumExtended';
    width: 80%;
    margin-top: 2vh;
    font-size: 1vw;
    text-align: left;
    margin-bottom: 2vh;
}
.dv_blog1_black[data-v-845db73a] {
    margin-top: 3vh;
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
}
.tx_blog1_destaque[data-v-845db73a] {
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    background-color: var(--dark-gray);
    font-size: 1.5vw;
    padding: 2vh;
}
.tx_blog1_subtitle[data-v-845db73a] {
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-family: 'NomineeMediumExtended';
    color: var(--light-yellow);
    background-color: var(--dark-brown);
    text-align: left;
}
.tx_blog1_subtitle_dest[data-v-845db73a] {
    font-family: 'NomineeMediumExtended';
    color: var(--dark-gray-font);
    background-color: white;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.3vw;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-line-pack: center;
        align-content: center;
    height: 8vh;
}
.dv_semana_container[data-v-845db73a] {
    margin-top: 1vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    color: var(--light-yellow);
    width: 90%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-item-align: center;
        align-self: center;
    margin-bottom: 1.3vh;
}
.tx_semana[data-v-845db73a], .tx_semana_mar[data-v-845db73a] {
    margin-top: 0.2vh;
    font-size: 0.8vw;
}
.tx_semana_mar[data-v-845db73a] {
    color: var(--light-yellow);
    -ms-flex-item-align: center;
        align-self: center;
    font-size: 0.8vw;
    margin-left: 1vw;
}
.tx_semana_t[data-v-845db73a] {
    font-family: 'NomineeMedium';
    font-size: 1.3vw;
}
.im_semana_t[data-v-845db73a] {
    height: 1.4vw;
    -o-object-fit: contain;
       object-fit: contain;
    /*background-color: red;*/
}
.blog2[data-v-845db73a] {
    margin-top: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 80%;
    /*background-color: pink;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.tx_blog2_title[data-v-845db73a] {
    font-family: 'NomineeMediumExtended';
    width: 100%;
    height: 8vh;
    background-color: var(--light-gray-font);
    color: white;
    font-size: 2.7vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    white-space: pre;
    margin-bottom: 2vh;
}
.tx_blog2_subtitle[data-v-845db73a] {
    background: var(--dark-brown);
    color: var(--light-yellow);
    /*font-family: 'NomineeMedium';*/
    /*display:inline;*/
    font-family: 'NomineeMedium';
    font-size: 1.2vw;
    line-height: 1.8vw;
    /*padding-bottom: -12px;*/
    padding-top: 0.2vw;
    padding-bottom: 0.1vw;
    padding-right: 0.2vw;
    padding-left: 0.2vw;
    max-height: 0.5vw;
}
.dv_blog2_subtitle[data-v-845db73a] {
    /*display: inline-block;*/
    color: white;
    width: 98%; /* Width to define where line break should appear */
    text-align: left;
    /*background-color: blue;*/
    /*margin-left: 2vw;*/
    margin-top: 1vh;
    margin-bottom: 1vh
}
.tx_blog2_subtitle2[data-v-845db73a] {
    color: var(--light-yellow);
    font-family: 'NomineeExtraLight';
    /*display:inline;*/
    /*font-size: 0.7vw;*/
    font-size: 0.9vw;
    line-height: 1.2vw;
    /*margin-left: 2vw;*/
    text-align: left;
    display: none;
}
.dv_blog2_evento_container1[data-v-845db73a] {
    width: 100%;
    overflow-x: scroll;
}
.dv_blog2_evento_container2[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    overflow: hidden;
}
.dv_blog2_evento_p1[data-v-845db73a] {
    height: 80%;
    width: 100%;
    /*background-color: yellow;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.dv_blog2_evento_p2[data-v-845db73a] {
    background-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;

    -webkit-box-direction: normal;

        -ms-flex-direction: column;

            flex-direction: column;
    height: 20%;
    /*background-color: red;*/
    width: 80%;
    overflow-y: hidden;
}
.dv_blog2_evento_im_mask[data-v-845db73a] {
    width: 100%;
    height: 3%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: -2%
}
.dv_blog2_evento[data-v-845db73a] {
    width: 18vw;
    height: 20vw;
    /*max-height: 20vw;*/
    /*background-color: blue;*/
    background-color: var(--dark-gray);
    margin-right: 2vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-y: hidden;
    padding-bottom: 2vh;
    /*background-color: red;*/
}
.dv_blog2_evento:hover > .dv_blog2_evento_p1[data-v-845db73a] {
    /*display: none;*/
    height: 0;
    width: 100%;/* Adicionado 27/10/2021 */
    -webkit-transition: height .3s linear;
    transition: height .3s linear;
}
.dv_blog2_evento:hover > .dv_blog2_evento_p2[data-v-845db73a] {
    height: 100%;
    -webkit-transition: height .3s linear;
    transition: height .3s linear;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.dv_blog2_evento:hover .dv_blog2_evento_im_mask[data-v-845db73a] {
    display: none;
}
.dv_blog2_evento:hover .tx_blog2_subtitle[data-v-845db73a] {
    display: none;
}
.dv_blog2_evento:hover .tx_blog2_subtitle2[data-v-845db73a] {
    display: unset;
}
.blog3[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    /*background-color: red;*/
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 80%;
    height: 60vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.blog3_p1[data-v-845db73a] {
    width: 65%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog3_p2[data-v-845db73a] {
    width: 32%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*background-color: red;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.blog3_p1_noticia[data-v-845db73a] {
    width: 92%;
    min-height: var(--height-news);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-bottom: 2vh;
    cursor: pointer;
}
.blog3_p1_container[data-v-845db73a] {
    width: 100%;
    height: 100%;
    background-color: var(--dark-brown);
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 3vh;
}
.blog3_p1_im[data-v-845db73a] {
    width: 27%;
    min-width: 27%;
    /*height: 100%;*/
    height: var(--height-news);
    position: relative;
    -o-object-fit: cover;
       object-fit: cover;
}
.blog3_p1_subcont[data-v-845db73a] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding-right: 2%;
    height: var(--height-news);
    /*position: relative;*/
    border-color: var(--light-gray-font);
    border-style: solid;
    border-width: 1px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-left: 2vw;
}
.blog3_tx1[data-v-845db73a] {
    color: white;
    font-family: 'NomineeMedium';
    font-size: 1vw;
    text-align: left;
}
.blog3_tx2[data-v-845db73a] {
    color: var(--light-yellow);
    font-family: 'NomineeExtraLight';
    font-size: 0.8vw;
    text-align: left;
    max-lines: 3;
    overflow-y: hidden;
}
.blog4[data-v-845db73a] {
    width: 80%;
    height: 60vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    padding-right: 2vw;
    padding-left: 2vw;
    /*padding-bottom: 2vw;*/
    border-style: solid;
    border-width: 2px;
    border-color: var(--dark-gray);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.blog4_tx_title[data-v-845db73a] {
    font-family: 'Hanie';
    font-size: 4vw;
    color: var(--dark-brown);
    line-height: 4vw;
    background-color: var(--light-yellow);
    /*background-color: blue;*/
    margin-top: -1vw;
    /*margin-bottom: -2vw;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 1vw;
}
.blog4_dv_container[data-v-845db73a] {
    /*margin-bottom: -4vh;*/
    /*background-color: purple;*/
    width: 100%;
    height: 52vh;
    overflow-x: scroll;
    overflow-y: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.blog4_dv_el[data-v-845db73a] {
    width: 25vw;
    min-width: 25vw;
    margin-right: 2vw;
}
.videoC[data-v-845db73a] {
    width: 100%;
    max-height: 55vh;
    /*object-fit: cover;*/
    /*object-fit: scale-down;*/
    -o-object-fit: fill;
       object-fit: fill;
    /*background-color: var(--dark-gray);*/
    background-color: black;
    padding: 0;
    /*height: 440px;*/
    /*display: none;*/
}
.im_selo[data-v-845db73a] {
    height: 20vh;
    width: 15vh;
    -o-object-fit: cover;
       object-fit: cover;
}
.im_pop[data-v-845db73a] {
    width: unset;
}

/************ BLOG **************/

/****************** SLIDER BANNER ******************/

/* celular com tella normal/grande */
@media (max-width:1049px)  {
.tx_title_galeria[data-v-845db73a] {
        margin-top: 9vh;
        margin-bottom: -7vh;
        font-size: 6vw;
}
.im_pop[data-v-845db73a] {
        width: 110%;
}
.videoC[data-v-845db73a] {
        background-color: unset;
}

    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
.top-bar-item[data-v-845db73a] {
        display: none;
}
.top-bar[data-v-845db73a] {
        width: 100vw;
        height: 7vh;
}
.root[data-v-845db73a] {
        width: 100vw;
        /*max-width: 100vw !important;*/
        /*background-color: red;*/
}
body[data-v-845db73a] {
        width: 100vw;
        max-width: 100vw !important;
        /*background-color: purple;*/
}
.dv_historia[data-v-845db73a] {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        height: auto;
        /*overflow-x: scroll;*/
}
.dv_historia_sub1[data-v-845db73a] {
        width: 80%;
        padding: 0;
}
.dv_historia_sub2[data-v-845db73a] {
        width: 80%;
        height: auto;
        padding-right: 0;
        margin-bottom: 5vh;
}
.tx_title_historia[data-v-845db73a] {
        font-size: 7vh;
}
.dv_colum_comodidades[data-v-845db73a] {
        /*background-color: red;*/
        width: 80%;
}
.dv_colum_comodidades_container[data-v-845db73a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-x: scroll;*/
        height: auto;
}
.dv_row_comodidades_container[data-v-845db73a] {
        height: 15vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        /*background-color: red;*/
        width: 93%;
}
.im_ic_comodidades[data-v-845db73a] {
        margin-right: 1vh;
        width: 4vh;
        height: 4vh;
}
.tx_title_comodidades[data-v-845db73a] {
        font-size: 8vh;
        margin-bottom: 0;
        /*background-color: yellow;*/
        /*height: 15vh;*/
        margin-bottom: -5vh;
}
.dv_popup_mail[data-v-845db73a], .dv_popup_mail2[data-v-845db73a], .dv_popup_mail3[data-v-845db73a], .dv_popup_checkin[data-v-845db73a], .dv_popup_image[data-v-845db73a] {
        width: 90vw;
        max-width: 90vw !important;
        /*background-color: red;*/
        padding: 3vw;
}
.dv_popup_checkin[data-v-845db73a] {
        width: 95vw;
        max-width: 95vw !important;
        height: 55.23vw;
}
.dv_popup_image[data-v-845db73a] {
        width: 80vw;
        max-width: 80vw !important;
        padding: 0;
}
.dv_localizacao_container[data-v-845db73a] {
        width: 100%;
        /*height: 100%;*/
        height: auto;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        /*overflow-x: scroll;*/
}
.dv_localizacao_sub1[data-v-845db73a] {
        width: 100%;
        height: 80vh;
}
.dv_localizacao_sub2[data-v-845db73a] {
        width: 100%;
        height: 50vh;
}
.tx_title_gastro[data-v-845db73a] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        /*margin-bottom: 5vh;*/
        /*background-color: orange;*/
}
.tx_descricao_gastro[data-v-845db73a] {
        margin-top: -2vh;
        text-align: right;
        padding-right: 2%;
        /*background-color: red;*/
}
.dv_colum_gastronomia_container[data-v-845db73a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-x: scroll;*/
        /*height: 85vh;*/
        height: auto;
}
.dv_colum_gastronomia[data-v-845db73a], .dv_colum_gastronomia3[data-v-845db73a] {
        width: 80%;
        /*height: 60%;*/
        height: 55vh;
}
.dv_colum_gastronomia3[data-v-845db73a] {
        margin-bottom: 5vh;
}
.dv_row_gastronomia_container[data-v-845db73a] {
        margin-top: 5vh;
        height: 15vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        width: 95%;
}
.dv_gastronomia[data-v-845db73a] {
        height: 45vh;
}
.dv_gastronomia_overlay[data-v-845db73a] {
        height: 45vh;
}
.dv_footbar_container[data-v-845db73a] {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
}
.dv_footbar_colum0[data-v-845db73a] {
        width: 40%;
        margin-left: 2vw;
}
.dv_footbar_colum[data-v-845db73a] {
        width: 40%;
}
.dv_footbar_colum2[data-v-845db73a] {
        display: none;
}
.dv_acomodacoes_tit_cont[data-v-845db73a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
.tx_acomodacaoes_subtitle[data-v-845db73a], .tx_acomodacaoes_subtitle_s[data-v-845db73a] {
        width: 30%;
        font-size: 1.5vh;
        margin-top: 1vh;
        margin-bottom: 1vh;
}
.tx_title_acomoda[data-v-845db73a] {
        /*width: 100%;*/
        font-size: 7vh;
        /*background-color: red;*/
}
.dv_acomodacoes_container[data-v-845db73a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*overflow-y: scroll;*/
        height: auto;
}
.dv_row_acomodacoes_container[data-v-845db73a] {
        width: 95%;
}
.dv_row_acomodacoes_container_ft[data-v-845db73a], .dv_row_comodidades_container_ft[data-v-845db73a], .dv_row_gastro_container_ft[data-v-845db73a] {
        width: 100%;
}
.dv_acomodacoes_sub1[data-v-845db73a] {
        width: 100%;
        height: 50vh;
}
.dv_descricao_acomoda[data-v-845db73a] {
        width: 100%;
        height: 60vh;
}
.tx_descricao_acomoda[data-v-845db73a] {
        height: 60vh !important;
}
.dv_reservas_container[data-v-845db73a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        /*height: 43vh;*/
        /*height: 80vh;*/
        /*overflow-y: scroll;*/
        height: auto;
        padding-top: 2vh;
}
.dv_colum_reservas[data-v-845db73a] {
        width: 80%;
        height: 45vh;
}
.dv_box_calendar[data-v-845db73a] {
        height: 35vh;
}
.sel_reservas[data-v-845db73a] {
        width: 10vw;
}
.sel_reservas2[data-v-845db73a] {
        width: 37vw;
}
.dv_reservas_tit_cont[data-v-845db73a] {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-top: 2vh;
        height: 15vh;
}
.dv_reservas_divisor[data-v-845db73a], .dv_reservas_texto[data-v-845db73a] {
        display: none;
}
.tx_title_reservas[data-v-845db73a] {
        width: 100%;
        font-size: 10vh;
}
.tx_title_newsletter[data-v-845db73a] {
        font-size: 7vh;
}
.dv_gastro_divisor[data-v-845db73a] {
        display: none;
}
.historia[data-v-845db73a], .comodidades[data-v-845db73a], .localizacao[data-v-845db73a], .gastronomia[data-v-845db73a], .acomodacoes[data-v-845db73a], .reservas[data-v-845db73a], .faleconosco[data-v-845db73a], .newsletter[data-v-845db73a] {
        height: auto;
}
.dv_home_selos[data-v-845db73a] {
        bottom: 2vh;
        right: 5vw;
}
.im_selo[data-v-845db73a] {
        height: 10vh;
        width: 7.5vh;
        -o-object-fit: cover;
           object-fit: cover;
}
.dv_descricao_acomoda[data-v-845db73a]::-webkit-scrollbar
    {
        width: 3vw;
}
.popup_overlay2[data-v-845db73a] {
        display: none;
}

    /*  Blog  */
.blog1[data-v-845db73a] {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-top: 10vh;
}
.blog1_gal[data-v-845db73a] {
        margin-top: 5vh;
}
.blog1_p1[data-v-845db73a] {
        width: 100%;
}
.blog1_p2[data-v-845db73a] {
        width: 100%;
}
.tx_blog2_title[data-v-845db73a] {
        font-size: 2vw;
}
.tx_blog1_subtitle_dest[data-v-845db73a] {
        font-size: 2.2vw;
}
.tx_semana_t[data-v-845db73a] {
        font-size: 3vw;
}
.tx_semana[data-v-845db73a] {
        font-size: 2vw;
}
.im_semana_t[data-v-845db73a] {
        height: 3.5vw;
}
.tx_semana_mar[data-v-845db73a] {
        font-size: 2vw;
}
.tx_inspira[data-v-845db73a] {
        font-size: 10vw;
        bottom: -1vh;
}
.dv_blog1_inspira_title[data-v-845db73a] {
        margin-top: -8vw;
        margin-left: -0.7vw;
}
.tx_texto_inspira[data-v-845db73a] {
        font-size: 2.5vw;
}
.tx_autor[data-v-845db73a] {
        font-size: 2vw;
}
.blog3[data-v-845db73a] {
        /*height: 100vh;*/
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: auto;
}
.blog3_p1[data-v-845db73a] {
        width: 100%;
        height: auto;
        /*height: 50vh;*/
}
.blog3_p2[data-v-845db73a] {
        margin-top: 2vh;
        width: 100%;
        height: 48vh;
}
.blog3_tx1[data-v-845db73a] {
        font-size: 2vw;
}
.blog3_tx2[data-v-845db73a] {
        font-size: 1.8vw;
}
.tx_blog1_destaque[data-v-845db73a] {
        font-size: 2.5vw;
}
.blog3_p1_noticia[data-v-845db73a] {
        min-height: var(--height-news_m);
}
.blog3_p1_im[data-v-845db73a] {
        height: var(--height-news_m);
}
.blog3_p1_subcont[data-v-845db73a] {
        height: var(--height-news_m);
}
.dv_blog2_evento[data-v-845db73a] {
        width: 40vw;
        height: 50vw;
}
.dv_blog2_subtitle[data-v-845db73a] {
        width: 100%;
        margin-top: 0;
}
.tx_blog2_subtitle[data-v-845db73a] {
        font-size: 2.7vw;
        line-height: 2.7vw;
        padding-top: 0.4vh;
        padding-bottom: 0.1vh;
}
.tx_blog2_subtitle2[data-v-845db73a] {
        font-size: 2.3vw;
        line-height: 2.6vw;
}
.im_arrow[data-v-845db73a] {
        width: 8vw;
        height: 8vw;
        padding: 8vw;
        padding-left: 1vw;
}
.dv_arrow_container[data-v-845db73a] {
        margin-top: 23vw;
}
.dv_blog2_evento_p2[data-v-845db73a] {
        height: 6vh;
        vertical-align: top;
}
.tx_popup4[data-v-845db73a] {
        font-size: 2vh;
}
.blog4_dv_container[data-v-845db73a] {
        /*background-color: red;*/
        height: 57vh;
}
.blog4_dv_el[data-v-845db73a] {
        /*width: 40vw;*/
        min-width: 60vw;
}
}

/* celular com tel pequena */
@media (max-width:370px)  {
.dv_row_acomodacoes_container[data-v-845db73a] {
        width: 75%;
}
.dv_row_comodidades_container[data-v-845db73a] {
        width: 75%;
}
.dv_row_gastronomia_container[data-v-845db73a] {
        width: 88%;
}
}
@media (min-width:1050px) {
    /* big landscape tablets, laptops, and desktops */
.top-bar-item[data-v-845db73a] {
        display: unset;
}
.dv_historia[data-v-845db73a] {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
}
.section[data-v-845db73a] {
        display: none;
}
}
@media (min-width:1900px) {
    /* big screens desktops */
.videoC[data-v-845db73a] {
        height: 45vh;
}
}





























































































































































































































































































































































































































































































































































































































































































































































































